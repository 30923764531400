import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { verifyStatus } from "../../../actions/userActions";
import RejectButtonFi from "./RejectButtonFi";
import { withRouter } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Alert } from "bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";
import { baseUrl } from "../../pages/baseUrl"
import VerifyDocument2 from "./VerifyDocument2";
class VerifyDocument1 extends React.Component {
  constructor(props) {
    super(props);
    const { user } = this.props.auth;
    this.state = {
      records: [],
      id: "",
      i_image: "",
      identity_status: "",
      reject: "",
      errors: {},
      type: 25,
      passport_status:"",
      disable:false,
      reject:""
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log("passportimageshopw", nextProps)
    if (nextProps.record) {
      this.setState({
        id: nextProps.record._id,
        passport_image: nextProps.record.passport_image,
        passport_image_back: nextProps.record.passport_image_back,
        passport_status: nextProps.record.passport_status,
        passport_number:nextProps.record.passport_number,
        doc_type: nextProps.record.doc_type,
        reject: nextProps.record.d5_rejectResion,
        records: nextProps.record,
        countryCode: nextProps.record.country_code,
        disable:nextProps.disable
      });
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (nextProps.userrecords) {
      this.setState({
        tstatus: nextProps.userrecords.status,
      });
    }
  }
  getData() {
    const { user } = this.props.auth;
    axios
      .post(baseUrl + "/api/rejectReason-By-Id-Data", { _id: this.props.match.params.id })
      .then(res => {
        this.setState({
          d1: res.data.d1_rejectResion,
          d2: res.data.d2_rejectResion,
          d3: res.data.d3_rejectResion,
          d4: res.data.d4_rejectResion,
          d5: res.data.d5_rejectResion,
        }, () => {
          this.setState({ isKycModalOpen: true })
        });
        console.log("res.datares.data", res.data)

      })
      .catch()
  }
  getNodesToRemoveFromElement = (stringContent) => {
    var div = document.createElement("div");
    div.innerHTML = stringContent;
    var text = div.textContent || div.innerText || "";
    return text;
  };

  handleCommentChange = (event, editor) => {
    var data = editor.getData();
    if (data) {
      this.setState({ description: data });
    }
    this.setState({
      showResults: this.state.name === null ? false : true,
    });
  };

  onUpdate = (e) => {
    e.preventDefault();

    const newUser = {
      _id: this.props.match.params.id,
      type: "d5",
    };
    this.setState({disable:true})
    this.props.verifyStatus(newUser);
  };
  onChange = (e) => {
    if (e.target.id === "reject") {
      this.setState({ reject: e.target.value });
    }
  };
  openModel = () => {
    this.setState({ isKycModalOpen: true })
  }
  closeModel = () => {
    this.setState({ isKycModalOpen: false })
  }

  render() {
    const { errors } = this.state;
    const { user } = this.state;
    return (
      <>{(this.state.passport_status !== undefined) && (
        <>
          <div className={`${this.state.doc_type=="National Identity Card"?"col-12":"col-md-6"}`}>
            <form noValidate onSubmit={(e) => this.onUpdate(e)} id="update-user">
              <p className="mb-3"><b>Document Type : </b>{this.state.doc_type?this.state.doc_type:"Pending"}</p>
              <p className="mb-3"><b>Document Number : </b>{this.state.passport_number?this.state.passport_number:"Pending"}</p>
              <p className="mb-3">
                <b>{this.state.doc_type} Image:</b>{" "}
                {this.state.passport_status == 3  ? (
                  <button
                  onClick={(e) => {
                    e.preventDefault()
                    this.getData()

                  }}
                  // to={
                  //   "/reject-reason/" + this.props.match.params.id + "/" + 22
                  // }
                  type="button"
                  className="btn btn_man"
                >
                  Rejected <i className="fa fa-info-circle"></i>
                </button>
                ) : this.state.passport_status == 2 ? (
                  "Verified"
                ) : (
                 "Pending"
                )}
              </p>
              <div className="row">

              <div className={`${this.state.doc_type=="National Identity Card"?"col-md-6":"col-12"}`}>

              {this.state.passport_image? <a href={baseUrl + "/static/upload/" + this.state.passport_image} target="_blank">
                <img
                  src={baseUrl + "/static/upload/" + this.state.passport_image}
                  className="img-thumbnail"
                  alt="Cinque Terre"
                ></img>
              
              </a>:""}
              </div>
              <div className="col-md-6">

              {this.state.passport_image_back && this.state.doc_type=="National Identity Card"? <a href={baseUrl + "/static/upload/" + this.state.passport_image_back} target="_blank">
                <img
                  src={baseUrl + "/static/upload/" + this.state.passport_image_back}
                  className="img-thumbnail"
                  alt="Cinque Terre"
                ></img>
              
              </a>:""}
              </div>
              </div>
              {this.props.match.params.type == 2 && (
                <div
                  style={{
                    display:
                      this.state.passport_status !== "2" &&
                        this.state.passport_status !== "3"
                        ? "block"
                        : "none",
                  }}
                >
                  {/* <button type="button"  >Verify</button> */}
                </div>
              )}
               {this.props.match.params.type == 2 &&
              <div style={{ display: (this.state.passport_status !== '2' && this.state.passport_status !== '3' && this.state.passport_status !== '0'  ? 'block' : 'none') }}>
                {this.state.passport_status?<button className="btn btn-primary" type="submit" disabled={this.state.disable}>Verify</button>:""}
                
              </div>
            }
            </form>
           
            {this.props.match.params.type && this.props.match.params.type == 2 && this.state.passport_status !== '2' && this.state.passport_status !== '3'  && this.state.passport_status !== '0'?
              <RejectButtonFi record={this.state.records} docStatus={this.state.passport_status} reject={this.state.reject} />:""
            }
            <Modal
              show={this.state.isKycModalOpen}
              onHide={this.closeKycModal}
              backdrop="static"
              keyboard={false}
              className="rejectbody"
            >
              <Modal.Body>
                <div className="kyc_popop tc">
                  <div ref={(subtitle) => (subtitle = subtitle)}>
                    <h4 className="man_hadding">Reject Reason</h4>
                    {console.log("reasonofreject", this.state.d5)}
                    {this.state.type == 25 &&
                      <p className="rejectReason mb-3 mt-3">{this.state.d5}</p>
                    }
                  </div>
                  <div>
                    <button className="w100px btn btn-primary" onClick={this.closeModel}>
                      Okay!
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          {/* {this.props.match.params.type == 2 &&
              this.state.identity_status !== "2" &&
              this.state.identity_status !== "3" && (
                <RejectButtonFi
                  record={this.state.records}
                  docStatus={this.state.identity_status}
                  rejectStatus={this.state.reject}
                />
              )} */}
          <hr />
        </>
      )}
      </>
    );
  }
}

VerifyDocument1.propTypes = {
  verifyStatus: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { verifyStatus })(
  withRouter(VerifyDocument1)
);
