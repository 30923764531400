
import React, { useState, useEffect } from 'react'
import { baseUrl } from '../pages/baseUrl'
import axios from "axios"
import $ from 'jquery';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function UpdateMarketTokenModal(props) {
    console.log("props", props)
    const [state, setState] = useState(props)
    const [id, setId] = useState("")
    const [date, setDate] = useState("")
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [image, setImage] = useState("")
    const [imageUrl, setImageUrl] = useState("")
    const [errorState, setErrorState] = useState({})

    const [dateErr, setDateErr] = useState("")
    const [titleErr, setTitleErr] = useState("")
    const [linkErr, setLinkErr] = useState("")
    const [descriptionErr, setDescriptionErr] = useState("")
    const [imageErr, setImageErr] = useState("")
    const [language, setLanguage] = useState("en")
    const [blogLink, setBlogLink] = useState("")

    useEffect(() => {
        setState(props.currentRecord)
        // setDescription(props.currentRecord?.description ? props.currentRecord?.description : "")
        // setTitle(props.currentRecord.title)
        // setDate(props.currentRecord.date)
        // setId(props.currentRecord.id)
        
        setImageUrl(baseUrl + "/static/market_token/" +props.currentRecord.symbol)
        // setLanguage(props.currentRecord.lang)
        // setBlogLink(props.currentRecord.link)
    }, [props])

    const handleChange = (e) => {
        let error = { ...errorState };
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
        error[name] = "";
        if (value == "") {
            error[name] = "This field is required"
        } else {
            if (name == "quantity" || name == "user_limit") {
                if (value <= 0) {
                    error[name] = "Please enter valid number"
                }
            }
            else if (name == "name") {
                if (value.length <= 2) {
                    error[name] = "Please enter more then 2 charater."
                }
            }
            else if(name=="price") {
                if (value == 0) {
                    error[name] = "The Field is required";
                }                
                else if (value < 0) {
                    error[name] = "The Price should not be negative";
                }
            }
        }
        setErrorState(error)
    }

    const handleChange1 = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
        // if (name === "date") {
        //     setDate(value)
        //     if (value === "") {
        //         setDateErr("This field is required")
        //         return false
        //     }
        //     setDateErr("")
        // }
        // if (name === "title") {
        //     setTitle(value)
        //     if (value == "") {
        //         setTitleErr("This field is required")
        //         return false
        //     }
        //     setTitleErr("")
        // }
        // if (name === "lang") {
        //     setLanguage(value)

        // }

        // if (name == "link") {
        //     setBlogLink(value)
        //     let reg = new RegExp("^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");
        //     if (value == "") {
        //         setLinkErr("This field is required")
        //         return false
        //     } else if (!reg.test(value)) {
        //         setLinkErr("Please Enter valid Url")
        //         return false
        //     }
        //     setLinkErr("")
        // }
        // if(name==="description"){
        //     setDescription(value)
        //     if(value===""){
        //         setDescriptionErr("This field is required")
        //         return false
        //     }
        //     setDescriptionErr("")
        // }
    }
    const handleImage = (e) => {
        let { value } = e.target;

        const icon = e.target.files[0];

        if (value !== "" || value !== undefined) {
            setImage(e.target.files[0]);
            setImageUrl(URL.createObjectURL(e.target.files[0]));
            setImageErr("");
        } else {
            setImage(e.target.files[0]);
            setImageUrl("");
            setImageErr("This field is required");
        }
        if (!icon.name.match(/\.(jpg|jpeg|png)$/)) {
            setImageErr("Select valid image format");
            return false;
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault()

        let error = {};
        if (state.name == undefined || state.name === "") {
            error['name'] = "This field is required";
            // setDateErr("This field is required")
            // return false
        }else {
            if(state.name.trim() == ""){
                error['name'] = "The input value is  Invalid";
            }
        }
        if (state.price == undefined || state.price === "") {
            error['price'] = "This field is required";
            //setTitleErr("This field is required")
            // return false
        }
        else {
            if (state.price < 0) {
                error['price'] = "The Price should not be negative";
            }
        }
        if (state.country_id == undefined || state.country_id === "") {
            error['country_id'] = "This field is required";
            // setLinkErr("This field is required")
            // return false
        }
        if (state.date_of_live == undefined || state.date_of_live === "") {
            error['date_of_live'] = "This field is required";
            //setDescriptionErr("This field is required")
            //  return false
        }
        if (state.quantity == undefined || state.quantity === "") {
            error['quantity'] = "This field is required";
            // setImageErr("This field is required")
            //return false
        }
        else {
            if (state.quantity < 0) {
                error['quantity'] = "The quantity should not be negative";
            }
        }
        if (state.user_limit == undefined || state.user_limit === "") {
            error['user_limit'] = "This field is required";
            //setImageErr("This field is required")
            //return false
        }
        else {
            if (state.user_limit < 0) {
                error['user_limit'] = "The User limit should not be negative";
            }
        }
        
        console.log(Object.keys(error).length);
        setErrorState(error)
        if (Object.keys(error).length == 0) {

            let formdata = new FormData
            formdata.append("name", state.name)
            formdata.append("price", state.price)
            formdata.append("country_id", state.country_id)
            formdata.append("symbol", image)
            formdata.append("date_of_live", state.date_of_live)
            formdata.append("quantity", state.quantity)
            formdata.append("user_limit", state.user_limit);
            formdata.append("id", state.id);


            axios.post(baseUrl + "/api/update_market_token", formdata).then((res) => {
                if (res.data.status) {
                    props.setRefresh(!props.refresh)
                    $('#update-user-modal').modal('hide');
                    setState({})

                }
            }).catch((err) => {

            })
        }
    }
    return (
        <div>
            <div className="modal fade" id="update-user-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edit Market Token</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <form id="update-user1" onSubmit={handleSubmit}>
                                <div className='mb-3'>
                                    <label className='form-label'>Token Name:</label>
                                    <input type='text' name="name" value={state.name} onChange={handleChange} className='form-control' />
                                    <span style={{ color: "red" }}>{errorState.name}</span>
                                </div>
                                <div className='mb-3'>
                                    <label className='form-label'>Country:</label>
                                    <select onChange={handleChange} value={state.country_id} name="country_id" className="form-select w-100 form-control">
                                        <option value="">Select</option>

                                        {props.countryList.map((val, index) =>
                                            <option value={val.id} key={index}>{val.name}</option>
                                        )}

                                    </select>
                                    <span style={{ color: "red" }}>{errorState.country_id}</span>

                                </div>
                                <div className='mb-3'>
                                    <label className='form-label'>Price:</label>
                                    <input type='number' name="price" value={state.price} onChange={handleChange} className='form-control' />
                                    <span style={{ color: "red" }}>{errorState.price}</span>
                                </div>
                                <div className='mb-3'>
                                    <label className='form-label'>Date of Live:</label>
                                    <input type="date" name="date_of_live" value={state.date_of_live} onChange={handleChange} className='form-control' min={new Date().toJSON().slice(0, 10)} />
                                    <span style={{ color: "red" }}>{errorState.date_of_live}</span>
                                </div>
                                {/* <div className='mb-3'>
                                    <label className='form-label'>Title:</label>
                                    <textarea name="title" value={state.name} onChange={handleChange} className='form-control' />
                                    <span style={{ color: "red" }}>{titleErr}</span>
                                </div> */}
                                <div className='mb-3'>
                                    <label className='form-label'>Available quantity:</label>
                                    <input type='number' name="quantity" value={state.quantity} onChange={handleChange} className='form-control' />

                                    <span style={{ color: "red" }}>{errorState.quantity}</span>
                                </div>
                                <div className='mb-3'>
                                    <label className='form-label'>Per User Limit:</label>
                                    <input type='number' name="user_limit" value={state.user_limit} onChange={handleChange} className='form-control' />

                                    <span style={{ color: "red" }}>{errorState.user_limit}</span>
                                </div>
                                <div className='mb-3'>
                                    <img
                                        style={{ width: "100px" }}
                                        src={imageUrl}
                                        className="img-fluid"
                                        alt=""
                                    />
                                    <label className='form-label'>Image:</label>
                                    <input type="file" name="image" onChange={handleImage} className='form-control' accept="image/png, image/jpeg, image/jpg" />
                                    <span style={{ color: "red" }}>{errorState.image}</span>
                                </div>




                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button
                                form="update-user1"
                                type="submit"
                                className="btn btn-primary">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateMarketTokenModal
