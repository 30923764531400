import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { addRoleData } from "../../actions/roleActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import $ from "jquery";
import classnames from "classnames";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

class AddRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      id: "",
      name: "",
      email: "",
      mobile: "",
      password: "",
      role: "",
      loan: 0,
      intrestRate: 0,
      marginCall: 0,
      liquidations: 0,
      cryptoCurrency: 0,
      pairManagement: 0,
      botManagement: 0,
      liquidityManagement: 0,
      buyOrder: 0,
      sellOrder: 0,
      manageUsers: 0,
      verifyComplete: 0,
      verifyPending: 0,
      fundRequest: 0,
      roleManager: 0,
      suportManagement: 0,
      pair_id: [],
      message: "",
      values: [],
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.auth.user.roleManager !== undefined &&
      this.props.auth.user.roleManager !== 1
    ) {
      this.props.history.push("/dashboard");
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.roledata !== undefined &&
      nextProps.roledata.role !== undefined &&
      nextProps.roledata.role.data !== undefined &&
      nextProps.roledata.role.data.message !== undefined &&
      nextProps.roledata.role.data.success
    ) {
      this.props.history.push("/role-manager");
    }
  }
  onChange = (e) => {
    this.setState({ errors: {} });
    this.setState({ [e.target.name]: e.target.value });
  };

  onRoleAdd = (e) => {
    e.preventDefault();
    const newdata = {
      name: this.state.name,
      email: this.state.email,
      mobile_no: this.state.mobile,
      role: this.state.role,
      loan: this.state.loan,
      intrestRate: this.state.intrestRate,
      marginCall: this.state.marginCall,
      liquidations: this.state.liquidations,
      cryptoCurrency: this.state.cryptoCurrency,
      pairManagement: this.state.pairManagement,
      botManagement: this.state.botManagement,
      liquidityManagement: this.state.liquidityManagement,
      buyOrder: this.state.buyOrder,
      sellOrder: this.state.sellOrder,
      manageUsers: this.state.manageUsers,
      fundRequest: this.state.fundRequest,
      roleManager: this.state.roleManager,
      suportManagement: this.state.suportManagement,
      verifyComplete: this.state.verifyComplete,
      verifyPending: this.state.verifyPending,
      password: this.state.password,
    };
    console.log(newdata, "data");
    // return;
    this.props.addRoleData(newdata, this.props.history);
  };
  render() {
    const { errors } = this.state;

    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <button className="btn btn-link mt-3" id="menu-toggle">
                <FontAwesomeIcon icon={faList} />
              </button>
              <h3 className="mt-2 man_hadding">Add Role</h3>
              <div className="in_page row update-role-main-outer">
                <br />
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <div className=" padding_d">
                    <ul className="nav nav_left_tab">
                      <li className="nav-item">
                        <a
                          className="active show text-black"
                          data-toggle="tab"
                          href="#details"
                        >
                          <i className="la la-mobile-phone l_i"></i>
                          Role Add
                        </a>
                      </li>
                    </ul>
                    <hr />
                    <form noValidate onSubmit={this.onRoleAdd} id="add-user">
                      <div className="white_box">
                        <div className="tab-content ">
                          <div className="tab-pane active show" id="details">
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label
                                  className="text-black"
                                  htmlFor="contestStatus"
                                >
                                  Role Type
                                </label>
                              </div>
                              <div className="col-md-9">
                                <select
                                  class="browser-default custom-select"
                                  name="role"
                                  onChange={this.onChange}
                                >
                                  <option>Please Select</option>
                                  <option value="Manager">Manager</option>
                                  <option value="Employee">Employee</option>
                                </select>
                                <span className="text-danger">
                                  {errors.role}
                                </span>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label className="text-black" htmlFor="name">
                                  Full Name
                                </label>
                              </div>
                              <div className="col-md-9">
                                <input
                                  onChange={this.onChange}
                                  value={this.state.name}
                                  error={errors.name}
                                  name="name"
                                  type="text"
                                  className={classnames("form-control", {
                                    invalid: errors.name,
                                  })}
                                />
                                <span className="text-danger">
                                  {errors.name}
                                </span>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label className="text-black" htmlFor="email">
                                  Email
                                </label>
                              </div>
                              <div className="col-md-9">
                                <input
                                  onChange={this.onChange}
                                  value={this.state.email}
                                  error={errors.email}
                                  name="email"
                                  type="text"
                                  className={classnames("form-control", {
                                    invalid: errors.email,
                                  })}
                                />
                                <span className="text-danger">
                                  {errors.email}
                                </span>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label className="text-black" htmlFor="mobile">
                                  Mobile Number
                                </label>
                              </div>
                              <div className="col-md-9">
                                <PhoneInput
                                  country={"us"}
                                  value={this.state.mobile}
                                  onChange={(phone) => {
                                    this.setState({ mobile: phone });
                                    if (!phone) {
                                      this.setState({
                                        errors: {
                                          mobile: "This filed is required",
                                        },
                                        submitBtnDisable: false,
                                      });
                                      return false;
                                    }

                                    if (phone.length < 4) {
                                      this.setState({
                                        errors: {
                                          mobile:
                                            "Please enter a valid phone number",
                                        },
                                        submitBtnDisable: false,
                                      });
                                      return false;
                                    }
                                    this.setState({ errors: { mobile: "" } });
                                  }}
                                  placeholder="Mobile"
                                  enableSearch={true}
                                  containerClass="baseclass"
                                  inputClass="somebaseclass"
                                  countryCodeEditable={false}
                                  name="mobile_number"
                                  inputProps={{
                                    autoFocus: true,
                                  }}
                                />

                                {/* <input
                                  onChange={this.onChange}
                                  value={this.state.mobile}
                                  error={errors.mobile}
                                  name="mobile"
                                  type="text"
                                  className={classnames("form-control", {
                                    invalid: errors.mobile,
                                  })}
                                /> */}
                                <span className="text-danger">
                                  {errors.mobile}
                                </span>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label
                                  className="text-black"
                                  htmlFor="password"
                                >
                                  Password
                                </label>
                              </div>
                              <div className="col-md-9">
                                <input
                                  onChange={this.onChange}
                                  value={this.state.password}
                                  error={errors.password}
                                  name="password"
                                  type="password"
                                  className={classnames("form-control", {
                                    invalid: errors.password,
                                  })}
                                />
                                <span className="text-danger">
                                  {errors.password}
                                </span>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label
                                  className="text-black"
                                  htmlFor="cryptoCurrency"
                                >
                                  Currency Manage
                                </label>
                              </div>
                              <div className="col-md-9">
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={this.state.cryptoCurrency == 1}
                                      onChange={this.onChange}
                                      value="1"
                                      name="cryptoCurrency"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={this.state.cryptoCurrency == 0}
                                      onChange={this.onChange}
                                      value="0"
                                      name="cryptoCurrency"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    No
                                  </label>
                                </div>
                                <span className="text-danger">
                                  {errors.contestStatus}
                                </span>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label
                                  className="text-black"
                                  htmlFor="verifyComplete"
                                >
                                  Kyc Manage
                                </label>
                              </div>
                              <div className="col-md-9">
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={this.state.verifyComplete == 1}
                                      onChange={this.onChange}
                                      value="1"
                                      name="verifyComplete"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={this.state.verifyComplete == 0}
                                      onChange={this.onChange}
                                      value="0"
                                      name="verifyComplete"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label
                                  className="text-black"
                                  htmlFor="manageUsers"
                                >
                                  Manage Users
                                </label>
                              </div>
                              <div className="col-md-9">
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={this.state.manageUsers == 1}
                                      onChange={this.onChange}
                                      value="1"
                                      name="manageUsers"
                                      type="radio"
                                      className="form-check-input"
                                      placeholder="Enter Document No"
                                    />
                                    <span className="text-danger">
                                      {errors.idCard}
                                    </span>
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={this.state.manageUsers == 0}
                                      onChange={this.onChange}
                                      value="0"
                                      name="manageUsers"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label
                                  className="text-black"
                                  htmlFor="buyOrder"
                                >
                                  Order List
                                </label>
                              </div>
                              <div className="col-md-9">
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={this.state.buyOrder == 1}
                                      onChange={this.onChange}
                                      value="1"
                                      name="buyOrder"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={this.state.buyOrder == 0}
                                      onChange={this.onChange}
                                      value="0"
                                      name="buyOrder"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    No
                                  </label>
                                </div>
                                <span className="text-danger">
                                  {errors.contestStatus}
                                </span>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label
                                  className="text-black"
                                  htmlFor="intrestRate"
                                >
                                  Commission Manage
                                </label>
                              </div>
                              <div className="col-md-9">
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={this.state.intrestRate == 1}
                                      onChange={this.onChange}
                                      value="1"
                                      name="intrestRate"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={this.state.intrestRate == 0}
                                      onChange={this.onChange}
                                      value="0"
                                      name="intrestRate"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label
                                  className="text-black"
                                  htmlFor="suportManagement"
                                >
                                  Support Manage
                                </label>
                              </div>
                              <div className="col-md-9">
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={this.state.suportManagement == 1}
                                      onChange={this.onChange}
                                      value="1"
                                      name="suportManagement"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={this.state.suportManagement == 0}
                                      onChange={this.onChange}
                                      value="0"
                                      name="suportManagement"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                            {/* <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label className="text-black" htmlFor="roleManager">Role Manage</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label text-black">

                                                                        <input
                                                                            checked={this.state.roleManager == 1}
                                                                            onChange={this.onChange}
                                                                            value="1"
                                                                            name="roleManager"
                                                                            type="radio" className="form-check-input"
                                                                        />
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label text-black">
                                                                        <input
                                                                            checked={this.state.roleManager == 0}
                                                                            onChange={this.onChange}
                                                                            value="0"
                                                                            name="roleManager"
                                                                            type="radio" className="form-check-input"
                                                                        />
                                                                        No
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div> */}
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label
                                  className="text-black"
                                  htmlFor="fundRequest"
                                >
                                  Withdraw Manage
                                </label>
                              </div>
                              <div className="col-md-9">
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={this.state.fundRequest == 1}
                                      onChange={this.onChange}
                                      value="1"
                                      name="fundRequest"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={this.state.fundRequest == 0}
                                      onChange={this.onChange}
                                      value="0"
                                      name="fundRequest"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label
                                  className="text-black"
                                  htmlFor="marginCall"
                                >
                                  Deposite Manage
                                </label>
                              </div>
                              <div className="col-md-9">
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={this.state.marginCall == 1}
                                      onChange={this.onChange}
                                      value="1"
                                      name="marginCall"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={this.state.marginCall == 0}
                                      onChange={this.onChange}
                                      value="0"
                                      name="marginCall"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label
                                  className="text-black"
                                  htmlFor="botManagement"
                                >
                                  Referral Manage
                                </label>
                              </div>
                              <div className="col-md-9">
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={this.state.botManagement == 1}
                                      onChange={this.onChange}
                                      value="1"
                                      name="botManagement"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={this.state.botManagement == 0}
                                      onChange={this.onChange}
                                      value="0"
                                      name="botManagement"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label
                                  className="text-black"
                                  htmlFor="liquidityManagement"
                                >
                                  Liquidity Manage
                                </label>
                              </div>
                              <div className="col-md-9">
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={
                                        this.state.liquidityManagement == 1
                                      }
                                      onChange={this.onChange}
                                      value="1"
                                      name="liquidityManagement"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={
                                        this.state.liquidityManagement == 0
                                      }
                                      onChange={this.onChange}
                                      value="0"
                                      name="liquidityManagement"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label className="text-black" htmlFor="loan">
                                  Blog
                                </label>
                              </div>
                              <div className="col-md-9">
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={this.state.loan == 1}
                                      onChange={this.onChange}
                                      value="1"
                                      name="loan"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={this.state.loan == 0}
                                      onChange={this.onChange}
                                      value="0"
                                      name="loan"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                            {/* <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label className="text-black" htmlFor="pairManagement">Pair Management</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label text-black">

                                                                        <input
                                                                            checked={this.state.pairManagement == 1}
                                                                            onChange={this.onChange}
                                                                            value="1"
                                                                            name="pairManagement"
                                                                            type="radio" className="form-check-input"


                                                                        />
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label text-black">
                                                                        <input
                                                                            checked={this.state.pairManagement == 0}
                                                                            onChange={this.onChange}
                                                                            value="0"
                                                                            name="pairManagement"
                                                                            type="radio" className="form-check-input"


                                                                        />
                                                                        No
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </div> */}

                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label
                                  className="text-black"
                                  htmlFor="liquidations"
                                >
                                  Live Account Balance
                                </label>
                              </div>
                              <div className="col-md-9">
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={this.state.liquidations == 1}
                                      onChange={this.onChange}
                                      value="1"
                                      name="liquidations"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check-inline">
                                  <label className="form-check-label text-black">
                                    <input
                                      checked={this.state.liquidations == 0}
                                      onChange={this.onChange}
                                      value="0"
                                      name="liquidations"
                                      type="radio"
                                      className="form-check-input"
                                    />
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>

                            {/* <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label className="text-black" htmlFor="sellOrder">Sell Order</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label text-black">

                                                                        <input
                                                                            checked={this.state.sellOrder == 1}
                                                                            onChange={this.onChange}
                                                                            value="1"
                                                                            name="sellOrder"
                                                                            type="radio" className="form-check-input"


                                                                        />
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label text-black">
                                                                        <input
                                                                            checked={this.state.sellOrder == 0}
                                                                            onChange={this.onChange}
                                                                            value="0"
                                                                            name="sellOrder"
                                                                            type="radio" className="form-check-input"


                                                                        />
                                                                        No
                                                                    </label>
                                                                </div>
                                                                <span className="text-danger">{errors.contestStatus}</span>
                                                            </div>
                                                        </div> */}

                            {/* <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label className="text-black" htmlFor="verifyPending">Verify Pending</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label text-black">

                                                                        <input
                                                                            checked={this.state.verifyPending == 1}
                                                                            onChange={this.onChange}
                                                                            value="1"
                                                                            name="verifyPending"
                                                                            type="radio" className="form-check-input"


                                                                        />
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label text-black">
                                                                        <input
                                                                            checked={this.state.verifyPending == 0}
                                                                            onChange={this.onChange}
                                                                            value="0"
                                                                            name="verifyPending"
                                                                            type="radio" className="form-check-input"


                                                                        />
                                                                        No
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </div> */}

                            <hr />
                            <button type="submit" className="btn btn-success">
                              Save
                            </button>
                            <hr />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

AddRole.propTypes = {
  addRoleData: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  roledata: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  roledata: state.roledata,
  errors: state.errors,
});
export default connect(mapStateToProps, { addRoleData })(withRouter(AddRole));
