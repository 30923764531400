import React, { useState, useEffect } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faPlus } from "@fortawesome/free-solid-svg-icons";
import ReactDatatable from "@mkikets/react-datatable";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import axios from "axios";
import CryptoAddModal from "../partials/CryptoAddModal";
import CryptoUpdateModal from "../partials/CryptoUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import $ from "jquery";
import { clearCryptoData } from "../../actions/cryptoActions";
import { baseUrl } from "../../actions/baseUrl";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

function Coin_user_list({ auth }) {

    const history = useHistory();
    const dispatch = useDispatch();
  
    const query = new URLSearchParams(history.location.pathname);
    console.log(query)
    const [records, setRecords] = useState({list:[]});
    const [cryptoCheckedBoxes, setCryptoCheckedBoxes] = useState([]);
    const [currentRecord, setCurrentRecord] = useState({
        id: "",
        name: "",
        short_name: "",
        order_sequence: "",
        available_for_loan: "",
        available_for_p2p: "",
        icon: "",
    });

    const [totalAmount,setTotalAmount] = useState(0);

    useEffect(() => {
        if (
            auth.user.cryptoCurrency !== undefined &&
            auth.user.cryptoCurrency !== 1
        ) {
            history.push("/dashboard");
        }
        let pathnames = history.location.pathname.split("/");
        const coin = pathnames[pathnames.length - 1]
        console.log(coin)
        if (coin != undefined) {
            getData(coin);
        }
    }, []);


    const getData = (coin) => {
        axios
            .get(baseUrl + "/api/coin_user_amount_list/" + coin)
            .then((res) => {
                console.log(res.data);
                if (res.status === 200) {
                    setRecords(res.data.data);
                    amountCalculate(res.data.data.list)
                }
            })
            .catch();
    };

    const editRecord = (record) => {
        setCurrentRecord({
            id: record.id,
            name: record.name,
            short_name: record.short_name,
            chain: record.chain_type,
            url: record.coin_tron,
            order_sequence: record.order_sequence,
            icon: record.icon,
            is_deposit: record.is_deposit,
            is_withdrawal: record.is_withdrawal,
            is_trade: record.is_trade,
            is_show: record.is_show,
            is_tab: record.is_tab,
            is_new_launch: record.is_new_launch,
        });
    };

    const amountCalculate =  (record) =>{
        //let temp = currentRecord;
        let amount = 0;
        record.map((val)=>{
            amount+=val.coin_amount;
        })
        setTotalAmount(amount)
    }


    const columns = [
        {
            key: "id",
            text: "#",
            className: "id",
            align: "left",
            sortable: true,
            cell: (row, index) => index + 1,
        },
        {
            key: "email",
            text: "Email",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "coin_amount",
            text: "Amount",
            className: "short_name",
            align: "left",
            sortable: true,
        },
    ];

    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Crypto Currency List",
        no_data_text: "No record found!",
        button: {
            print: true,
            csv: true,
        },
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };

    const pageChange = (pageData) => {
        console.log("OnPageChange", pageData);
    };

    return (
        <div>
            <Navbar />
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <CryptoAddModal />
                <CryptoUpdateModal currentRecord={currentRecord} />
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <button className="btn btn-link mb-3" id="menu-toggle">
                            <FontAwesomeIcon icon={faList} /> Add
                        </button>

                        <h4 className="mt-2 man_hadding mb-3">{records?.coin_detail?.name} coin user List</h4>
                        <h5>Total Amount: {totalAmount}</h5>
                        <div className="white_box">
                            <button
                                className="btn btn-primary float-right mb-3 mr-2"
                                data-toggle="modal"
                                data-target="#add-user-modal"
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                Add
                            </button>
                            <ReactDatatable
                                config={config}
                                records={records.list}
                                columns={columns}
                                onPageChange={pageChange}
                            />
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    );
}

Coin_user_list.propTypes = {
    auth: PropTypes.object.isRequired,
    cryptodata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    cryptodata: state.cryptodata,
    records: state.records,
});

export default connect(mapStateToProps)(Coin_user_list);
