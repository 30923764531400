import React,{useState,useEffect} from 'react'
import ReactDatatable from '@mkikets/react-datatable';
import axios from "axios"
import { baseUrl } from './baseUrl';
import { toast, ToastContainer} from "react-toastify";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import $ from 'jquery';
function OpenPair() {
    const [record, setRecord] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [coinPairList, setCoinPairList] = useState([])
    const [coinPairId, setcoinPairId] = useState("")
    useEffect(() => {
      getCoinPair()
      getOpenPair()
    }, [refresh])
    const getCoinPair=()=>{
        axios.get(baseUrl+"/api/get-open-pairs").then((res)=>{
          console.log("res===",res)
            if(res.data.status){
                setCoinPairList(res.data.data)
            }
            else{
                setCoinPairList([])
            }
          }).catch((err)=>{
      
          })
    }
    const getOpenPair=()=>{
      axios.get(baseUrl+"/api/get-open-pair").then((res)=>{
        
          if(res.data.status){
            setRecord(res.data.data)
            setcoinPairId(res.data.data[0].id)
          }
          else{
            setRecord([])
          }
        }).catch((err)=>{
    
        })
  }
    const handleChange=(e)=>{
     setcoinPairId(e.target.value)
    }
    const columns = [
      {
        // key: "id",
        text: "#",
        className: "id",
        align: "left",
        sortable: true,
        cell: (row, index) => index + 1,
      },
      {
        key: "first_name",
        text: "Pair",
        className: "Pair",
        align: "left",
        sortable: true,
        cell:(res)=>{
          return <span>{res.first_name}/{res.second_name}</span>
        }
      },
   
    
     
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: record => {
            return (
                <>
                    <button
                        data-toggle="modal"
                        data-target="#update-user-modal"
                        className="btn btn-primary btn-sm"
                        // onClick={() => editRecord(record)}
                        style={{marginRight: '5px'}}>
                        <i className="fa fa-edit"></i>
                    </button>
                    
                </>
            );
        }
    }
    ];
  
    const config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Buy Order List",
      no_data_text: "No user found!",
      button: {
        print: false,
        csv: false,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: false,
      show_filter: false,
      show_pagination: false,
      show_info: false,
    };
    const handleSubmit=(e)=>{
     e.preventDefault()
     const data={
      id:coinPairId
     }
     axios.post(baseUrl+"/api/update-open-pair",data).then((res)=>{
      if(res.data.success){
       setRefresh(!refresh)
       toast.success(res.data.message)
       $('#update-user-modal').modal('hide');
      }
}).catch((err)=>{

})
    }
    
  return (
    <div>
    <Navbar />
    <div className="" id="wrapper">
      <Sidebar />

      <div id="page-content-wrapper">
        <div className="container-fluid">
          <button className="btn btn-link mt-3" id="menu-toggle">
            <FontAwesomeIcon icon={faList} />
          </button>  
         
         
          <h4 className="mt-2 man_hadding mb-3">Default Pair</h4>
          <div className='white_box'>

          <ReactDatatable
            config={config}
            records={record}
            columns={columns}
           
          />
          </div>
          </div>
        
          <ToastContainer/>
        </div>
        <div className="modal fade" id="update-user-modal">
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Update Open Pair</h4>
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                </div>
                <div className="modal-body">
                    <form  id="update-user1" onSubmit={handleSubmit}>
                      <div className='mb-3'>
                          <select onChange={handleChange} value={coinPairId} className="form-select w-100 form-control">
                               {coinPairList.map((list)=>{
                                return (
                                  <option value={list.id}>{list.first_name}/{list.second_name}</option>
                                )
                               })}
                          </select>
                         {/* <span style={{color:"red"}}>{dateErr}</span> */}
                      </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button
                        form="update-user1"
                        type="submit"
                        className="btn btn-primary">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
      </div>
   
    </div>
  )
}

export default OpenPair
