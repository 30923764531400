import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@mkikets/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AddContest from "../partials/AddContest";
import { toast, ToastContainer } from "react-toastify";
import Moment from "moment";
import * as myConstList from "./baseUrl";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import { format } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
const baseUrl = myConstList.baseUrl;
class BuyOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FilterRecords: [],
    };

    this.columns = [
      {
        // key: "id",
        text: "#",
        className: "id",
        align: "left",
        sortable: true,
        cell: (row, index) => index + 1,
      },
      // {
      //   key: "AccHolder",
      //   text: "Name",
      //   className: "user_id",
      //   align: "left",
      //   sortable: true,
      // },
      // {
      //   key: "email",
      //   text: "Email",
      //   className: "email",
      //   align: "left",
      //   sortable: true,
      // },
      // {
      //   key: "mobile_no",
      //   text: "Mobile No.",
      //   className: "email",
      //   align: "left",
      //   sortable: true,
      // },
      {
        key: "per_price",
        text: "Buy Price",
        className: "amount",
        align: "left",
        sortable: true,
      },
      {
        key: "total_buy_get_amount",
        text: "Amount (QTY)",
        className: "amount",
        align: "left",
        sortable: true,
      },
      {
        key: "total_buy_spend_amount",
        text: "Total(AMT)",
        className: "price",
        align: "left",
        sortable: true,
      },

      {
        key: "buy_fees",
        text: "Buy Fees",
        className: "amount",
        align: "left",
        sortable: true,
      },
      {
        key: "pairname",
        text: "Pair",
        className: "stopLoss",
        align: "left",
        sortable: true,
        // cell: record => {
        //     console.log("records",record)
        //     return (
        //         <Fragment>
        //            {record.firstCoin}/{record.secondCoin}
        //         </Fragment>
        //     );
        // }
      },
      {
        key: "status",
        text: "Status",
        className: "status",
        align: "left",
        sortable: true,
      },
      {
        key: "created",
        text: "date",
        className: "date",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              {Moment.utc(record.created).local().format("lll")}
            </Fragment>
          );
        },
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Buy Order List",
      no_data_text: "No user found!",
      button: {
        print: true,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
      openActivityDateRange: false,
      activityDateRange: [
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 7),
          key: "selection",
        },
      ],
    };
    this.getData = this.getData.bind(this);
  }
  componentDidMount() {
    if (
      this.props.auth.user.buyOrder !== undefined &&
      this.props.auth.user.buyOrder !== 1
    ) {
      this.props.history.push("/dashboard");
    }
    this.getData();
  }
  componentWillReceiveProps(nextProps) {
    // if (
    //   nextProps.auth.user.buyOrder !== undefined &&
    //   nextProps.auth.user.buyOrder !== 1
    // ) {
    //   this.props.history.push("/dashboard");
    // }
    this.getData();
  }
  getData() {
    axios
      .get(baseUrl + "/api/buy-order-data", { search: "Buy" })
      .then((res) => {
        this.setState({ records: res.data });
      })
      .catch();
  }
  editRecord(record) {
    this.setState({ currentRecord: record });
  }
  componentDidUpdate() {
    console.log("FilterRecordsdsdss", this.state.FilterRecords);
  }
  deleteRecord(record) {
    axios
      .post(baseUrl + "/api/order-delete", { _id: record._id })
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch();
    this.getData();
  }
  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }

  supportClick = (e) => {
    var html = [];
    this.state.records.map((value, i) => {
      if (e.target.value == "pending") {
        if (value.status === "pending") {
          console.log("valuevaluevaluevalue", value, e.target.value);
          html.push(value);
        }
      }
      if (e.target.value === "completed") {
        if (value.status == "completed") {
          html.push(value);
        }
      }
      if (e.target.value === "deleted") {
        if (value.status == "deleted") {
          html.push(value);
        }
      }
      if (e.target.value == "edited") {
        if (value.status == "edited") {
          html.push(value);
        }
      }
      if (e.target.value === "select") {
        html.push(value);
      }
    });

    this.setState({ FilterRecords: html });
  };

  getDateWiseData = (startdate, enddate) => {
    const inputDate = new Date(startdate);
    const day = inputDate.getDate();
    const month = inputDate.getMonth() + 1; // Months in JavaScript are 0-based
    const year = inputDate.getFullYear();
    const inputDate1 = new Date(enddate);
    const day1 = inputDate1.getDate();
    const month1 = inputDate1.getMonth() + 1; // Months in JavaScript are 0-based
    const year1 = inputDate1.getFullYear();
    const formattedDateE = `${year1}-${month1}-${day1}`;
    const formattedDateS = `${year}-${month}-${day}`;
    const data = {
      startDate: formattedDateS,
      endDate: formattedDateE,
    };
    axios
      .post(baseUrl + "/api/buy-order-data-by-date", data)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ records: res.data });
        }
      })
      .catch((err) => {
        this.setState({ records: [] });
      });
  };

  showFilterList = () => {
    const html = [];

    html.push(
      <select
        className="form-control"
        id="exchange"
        name="supportFilter"
        onClick={this.supportClick}
      >
        <option value="select" id="">
          Select Status
        </option>
        <option value="pending">Pending</option>
        <option value="edited">Edited</option>
        <option value="deleted">Deleted</option>
        <option value="completed">Completed</option>
      </select>
    );

    return <div className="support-select">{html}</div>;
  };

  render() {
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />

          <div id="page-content-wrapper">
            <div className="container-fluid">
              <button className="btn btn-link mt-3" id="menu-toggle">
                <FontAwesomeIcon icon={faList} />
              </button>
              <div className="row">
                <div className="col-md-5">
                  <h4 className="mt-2 man_hadding mb-3">Buy Order</h4>
                </div>
                <div className="col-md-4 text-right">
                  <div className="lsItem headerSearchItem ">
                    <span
                      onClick={() =>
                        this.setState({
                          openActivityDateRange:
                            !this.state.openActivityDateRange,
                        })
                      }
                    >{`${format(
                      this.state.activityDateRange[0].startDate,
                      "MM/dd/yyyy"
                    )} to ${format(
                      this.state.activityDateRange[0].endDate,
                      "MM/dd/yyyy"
                    )}`}</span>

                    {this.state.openActivityDateRange && (
                      <DateRange
                        onChange={(item) => {
                          console.log("item", item);
                          this.setState({
                            activityDateRange: [item.selection],
                          });
                          this.getDateWiseData(
                            item.selection.startDate,
                            item.selection.endDate
                          );
                        }}
                        ranges={this.state.activityDateRange}
                      />
                    )}
                    <span
                      className="btn btn-primary"
                      onClick={() => {
                        this.setState({
                          openActivityDateRange:
                            !this.state.openActivityDateRange,
                        });
                      }}
                    >
                      Ok
                    </span>
                  </div>
                </div>
                <div className="col-md-3">{this.showFilterList()}</div>
              </div>

              <div className="white_box">
                <ReactDatatable
                  config={this.config}
                  records={
                    this.state.FilterRecords
                      ? this.state.FilterRecords
                      : this.state.records
                  }
                  columns={this.columns}
                  onPageChange={this.pageChange.bind(this)}
                />
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

BuyOrder.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
});

export default connect(mapStateToProps)(BuyOrder);
