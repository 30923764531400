import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";
import logo from "../images/logo-login.png"
import loginimg from "../images/login-img.jpg"
import {Link} from "react-router-dom";
class Login extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            errors: {}
        };
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
            this.setState({
                errors: {},
                email:"",
                password:''
            }); 
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }

        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors,
            });
        }
   
    }

    onChange = e => {
        this.setState({ errors:{
            
        } });
        this.setState({ [e.target.id]: e.target.value });
    };
    onSubmit = e => {
        e.preventDefault();
        const userData = {
            email: this.state.email,
            password: this.state.password
        };
        this.props.loginUser(userData);
    };

    render() {
        const { errors } = this.state;
        return (
            <div className="login-page-outer">
                <div className="container-fluid p-0">
                    <div className="row">
                 
                        <div className="col-md-4 m-auto login-box p-0">
                            <div className="login_logo text-center mb-4">
    						     <img src={logo}/>
    							 </div>
                              
                                <form noValidate onSubmit={this.onSubmit} className="white ">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.email}
                                        error={errors.email}
                                        id="email"
                                        type="email"
                                        className={classnames("form-control", {
                                            invalid: errors.email
                                        })}
                                    />
                                    <span className="text-danger">{errors.email}</span>
                                    <br/>
                                    <label htmlFor="password">Password</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.password}
                                        error={errors.password}
                                        id="password"
                                        type="password"
                                        className={classnames("form-control", {
                                            invalid: errors.password
                                        })}
                                    />
                                    <span className="text-danger">{errors.password}</span>

                                    {/* <div className="form-group mt-2">
        <Link to="/forgot-password" >Forgot Password?</Link>

      </div>  */}
                                    <p className="text-center pb-0 mt-2">
                                        <button
                                            type="submit"
                                            className="btn btn-large btn_admin mt-2 px-5 mt-3">
                                            Login
                                        </button>
                                    </p>
                                </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { loginUser }
)(Login);
