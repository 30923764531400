import { fas } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { baseUrl } from '../pages/baseUrl'
import axios from "axios"
import $ from 'jquery';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function AddBannerTextModal(props) {
    const [date, setDate] = useState("")
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [image, setImage] = useState("")
    const [imageUrl, setImageUrl] = useState("")
    const [dateErr, setDateErr] = useState("")
    const [titleErr, setTitleErr] = useState("")
    const [descriptionErr, setDescriptionErr] = useState("")
    const [language, setLanguage] = useState("en")
    const [imageErr, setImageErr] = useState("")

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "date") {
            setDate(value)
            if (value === "") {
                setDateErr("This field is required")
                return false
            }
            setDateErr("")
        }
        // if(name==="title"){
        //     setTitle(value)
        //     if(value==""){
        //         setTitleErr("This field is required")
        //         return false
        //     }
        //     setTitleErr("")
        // }
        if (name === "description") {
            setDescription(value)
            if (value === "") {
                setDescriptionErr("This field is required")
                return false
            }
            setDescriptionErr("")
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (description === "") {
            setDescriptionErr("This field is required")
            return false
        }
        // let formdata=new FormData
        // formdata.append("header",description)  

        axios.post(baseUrl + "/api/add_banner_text", { header: description,lang:language }).then((res) => {
            if (res.data.status) {
                props.setRefresh(!props.refresh)
                $('#add-user-modal').modal('hide');
                setDate("")
                setTitle("")
                setDescription("")
                setImage("")
                setLanguage("en")
                setImageUrl("")
            }
        }).catch((err) => {

        })
    }
    const onChange = (e) => {
        setLanguage(e.target.value )
      }

    const closeModal = () => {
        console.log("close");
        setDate("")       
        setImage("")
        setImageUrl("")
        setTitle("")
        setDescription("")
        setDateErr("")
        setTitleErr("")
        setDescriptionErr("")
        setImageErr("")
      }

    return (
        <div>
            <div className="modal fade" id="add-user-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Banner Text</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={closeModal}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <form id="update-user" onSubmit={handleSubmit}>
                            <div className='mb-3'>
                                    <label className='form-label'>Language:</label>
                                    <select onChange={onChange} value={language} name="lang" className="form-select w-100 form-control">

                                        <option value="en">English</option>
                                        <option value="ko">Korean</option>
                                        <option value="rus">Russian</option>
                                        <option value="per">Persian</option>
                                        <option value="ar">Arabic</option>


                                    </select>
                                    
                                </div>
                                <div className='mb-3'>
                                    <label className='form-label'>Title:</label>
                                    <textarea name="description" value={description} onChange={handleChange} className='form-control' />
                                    <span style={{ color: "red" }}>{descriptionErr}</span>
                                </div>






                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Close</button>
                            <button
                                form="update-user"
                                type="submit"
                                className="btn btn-primary">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddBannerTextModal
