
import React, { useState, useEffect } from 'react'
import { baseUrl } from '../pages/baseUrl'
import axios from "axios"
import $ from 'jquery';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
function UpdateBlogModal(props) {
  console.log("props", props)
  const [id, setId] = useState("")
  const [date, setDate] = useState("")
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [image, setImage] = useState("")
  const [imageUrl, setImageUrl] = useState("")

  const [dateErr, setDateErr] = useState("")
  const [titleErr, setTitleErr] = useState("")
  const [linkErr, setLinkErr] = useState("")  
  const [descriptionErr, setDescriptionErr] = useState("")
  const [imageErr, setImageErr] = useState("")
  const [language, setLanguage] = useState("en")
  const [blogLink, setBlogLink] = useState("")

  useEffect(() => {
    setDescription(props.currentRecord?.description ? props.currentRecord?.description : "")
    setTitle(props.currentRecord.title)
    setDate(props.currentRecord.date)
    setId(props.currentRecord.id)
    setImageUrl(props.currentRecord.image)
    setLanguage(props.currentRecord.lang)
    setBlogLink(props.currentRecord.link)
  }, [props])



  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === "date") {
      setDate(value)
      if (value === "") {
        setDateErr("This field is required")
        return false
      }
      setDateErr("")
    }
    if (name === "title") {
      setTitle(value)
      if (value == "") {
        setTitleErr("This field is required")
        return false
      }
      setTitleErr("")
    }
    if (name === "lang") {
      setLanguage(value)
      
    }

    if(name=="link") {
      setBlogLink(value)
      let reg = new RegExp("^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");
      if (value == "") {
        setLinkErr("This field is required")
        return false
      }else if(!reg.test(value)){
        setLinkErr("Please Enter valid Url")
        return false
      }
      setLinkErr("")
    }
    // if(name==="description"){
    //     setDescription(value)
    //     if(value===""){
    //         setDescriptionErr("This field is required")
    //         return false
    //     }
    //     setDescriptionErr("")
    // }
  }
  const handleImage = (e) => {
    let { value } = e.target;

    const icon = e.target.files[0];

    if (value !== "" || value !== undefined) {
      setImage(e.target.files[0]);
      setImageUrl(URL.createObjectURL(e.target.files[0]));
      setImageErr("");
    } else {
      setImage(e.target.files[0]);
      setImageUrl("");
      setImageErr("This field is required");
    }
    if (!icon.name.match(/\.(jpg|jpeg|png)$/)) {
      setImageErr("Select valid image format");
      return false;
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault()

    if (title === "") {
      setTitleErr("This field is required")
      return false
    }
    if (description === "") {
      setDescriptionErr("This field is required")
      return false
    }

    if (blogLink === "") {
      setLinkErr("This field is required")
      return false
    }

    let formdata = new FormData
    formdata.append("date", date)
    formdata.append("title", title)
    formdata.append("description", description)
    formdata.append("image", image)
    formdata.append("id", id)
    formdata.append("lang", language)
    formdata.append("link", blogLink)
    
    
    axios.post(baseUrl + "/api/blog-update", formdata).then((res) => {
      if (res.data.status) {
        props.setRefresh(!props.refresh)
        $('#update-user-modal').modal('hide');
        setDate("")
        setTitle("")
        setDescription("")
        setImage("")
        setImageUrl("")
      }
    }).catch((err) => {

    })
  }
  return (
    <div>
      <div className="modal fade" id="update-user-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Edit Blog</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
              <form id="update-user1" onSubmit={handleSubmit}>
                <div className='mb-3'>
                  <label className='form-label'>Language:</label>
                  <select onChange={handleChange} value={language} name="lang" className="form-select w-100 form-control">
                    <option value="en">English</option>
                    <option value="ko">Korean</option>
                    <option value="rus">Russian</option>
                    <option value="per">Persian</option>
                    <option value="ar">Arabic</option>
                  </select>

                </div>
                <div className='mb-3'>
                  <label className='form-label'>Date:</label>
                  <input type="date" name="date" value={date} onChange={handleChange} className='form-control' min={new Date().toJSON().slice(0, 10)} />
                  <span style={{ color: "red" }}>{dateErr}</span>
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Title:</label>
                  <textarea name="title" value={title} onChange={handleChange} className='form-control' />
                  <span style={{ color: "red" }}>{titleErr}</span>
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Link:</label>
                  <input type="text" name="link" value={blogLink} onChange={handleChange} className='form-control' />
                  <span style={{ color: "red" }}>{linkErr}</span>
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Description:</label>
                  {/* <textarea name="description" value={description} onChange={handleChange}  className='form-control'/> */}
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    config={{
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                      ],
                      heading: {
                        options: [
                          {
                            model: "paragraph",
                            title: "Paragraph",
                            class: "ck-heading_paragraph",
                          },
                          {
                            model: "heading1",
                            view: "h1",
                            title: "Heading 1",
                            class: "ck-heading_heading1",
                          },
                          {
                            model: "heading2",
                            view: "h2",
                            title: "Heading 2",
                            class: "ck-heading_heading2",
                          },
                          {
                            model: "heading3",
                            view: "h3",
                            title: "Heading 3",
                            class: "ck-heading_heading3",
                          },
                        ],
                      },
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data);
                    }}
                  />
                  <span style={{ color: "red" }}>{descriptionErr}</span>
                </div>
                <div className='mb-3'>
                  <img
                    style={{ width: "100px" }}
                    src={imageUrl}
                    className="img-fluid"
                    alt=""
                  />
                  <label className='form-label'>Image:</label>
                  <input type="file" name="image" onChange={handleImage} className='form-control' accept="image/png, image/jpeg, image/jpg" />
                  <span style={{ color: "red" }}>{imageErr}</span>
                </div>




              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              <button
                form="update-user1"
                type="submit"
                className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateBlogModal
