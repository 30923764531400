import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@mkikets/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DepositINRReject from "../partials/DepositINRReject";
import DepositINRAppove from "../partials/DepositINRAppove";
import $ from 'jquery'
import { toast, ToastContainer } from "react-toastify";
import * as myConstList from './baseUrl';
import Moment from 'moment';
const baseUrl = myConstList.baseUrl;
class DepositINR extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "",
                text: "Sr. No.",
                className: "transaction_id",
                align: "left",
                sortable: true,
                cell: (row, index) => index + 1
            },
            {
                key: "tx_id",
                text: "Transaction_id",
                className: "transaction_id",
                align: "left",
                sortable: true,
            },
            {
                key: "email",
                text: "Email",
                className: "email",
                align: "left",
                sortable: true,
            },
            {
                key: "mobile_no",
                text: "Mobile No.",
                className: "email",
                align: "left",
                sortable: true
            },

            {
                key: "",
                text: "Proof Image",
                className: "img-fluid",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <span>
                            <a href="#" data-toggle="modal" data-target="#supportImageModel"
                                onClick={() => this.setState({
                                    Image: baseUrl + "/static/inr_deposit/" + record.image
                                })}>
                                <img src={baseUrl + "/static/inr_deposit/" + record.image} width="60vh" className="img-fluid" />
                            </a>
                        </span>
                    );
                }
            },


            {
                key: "amount",
                text: "Amount",
                className: "amount",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <span>
                            {Math.abs(record.amount)}
                        </span>
                    );
                }
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <span>
                            {record.is_approved == "1" ? 'Pending' : "Approved"}
                        </span>
                    );
                }
            },
            {
                key: "created",
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                            {Moment(record.created).format('lll')}
                        </Fragment>
                    );
                }
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (

                        <Fragment>
                            <p className="mb-0">

                                {record.is_approved == '1' ? (<>
                                    <button
                                        // data-toggle="modal"
                                        // data-target="#update-user-modal-approve"
                                        className="btn btn-success"
                                        onClick={() => this.editRecord(record)}
                                    >
                                        <i className="fa fa-check"></i>
                                    </button>
                                    <button
                                        data-toggle="modal"
                                        data-target="#update-user-modal-reject"
                                        className="btn btn-danger"
                                        onClick={() => this.editRecords(record)}
                                    >
                                        <i className="fa fa-close"></i>
                                    </button>
                                </>

                                ) : record.is_approved == '2' ? (<button
                                    data-toggle="modal"
                                    className="btn btn-success"
                                // onClick={() => this.editRecord(record)}
                                >
                                    <span className="completed_btn2">Approved<i className="fa fa-check  ml-1"></i></span>
                                </button>) : (<>
                                    <button
                                        data-toggle="modal"
                                        data-target="#update-user-modal-reject"
                                        className="btn btn-danger"
                                        onClick={() => this.editRecords(record)}
                                    >
                                        <span className="completed_btn2">Rejected<i className="fa fa-close  ml-1"></i></span>
                                    </button> </>
                                )}
                            </p>
                        </Fragment>

                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Fund Request List",
            no_data_text: 'No user found!',
            button: {

                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
                id: '',
                coin_amount: '',
                user_id: '',
                approve: '',
                reject: "",
                status: ''
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        axios
            .get(baseUrl + "/api/userdepositHistory")
            .then(res => {
                this.setState({ records: res.data })
            })
            .catch()
    }

    editRecord(record) {
        axios
            .post(baseUrl + "/api/InrStatus-deposit", {
                id: record.id,
                user_id: record.user_id,
                email: record.email,
                amount: record.amount,
                type: '1',
            })
            .then((res) => {
                if (res.status === 200) {
                    $("#update-user-modal-approve").modal("hide");
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    this.getData()
                    // setTimeout(function () {
                    //     window.location.reload();
                    // }, 3000);
                }
            })
            .catch();
        this.setState({ currentRecord: record });
    }
    editRecords(record) {
        this.setState({ currentRecord: record });
    }



    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }
    supportClick = (e) => {
        var html = []

        this.state.records.map((value) => {
            console.log("recordsrecords",value.is_approved,e.target.value)
            if (e.target.value == "pending") {
                if (value.is_approved == "1") {
                    html.push(value)
                }
            }
            if (e.target.value == "completed") {
                if (value.is_approved == "2") {
                    html.push(value)
                }
            }
            if (e.target.value == "deleted") {
                if (value.is_approved == "3") {
                    html.push(value)
                }
            }
            if (e.target.value == "select") {
                html.push(value)
            }

        })
        this.setState({ FilterRecords: html })

    }

    showFilterList = () => {


        const html = []

        html.push(
            <select className="form-control" id="exchange" name="supportFilter" onClick={this.supportClick}>
                <option value="select" id="">select</option>
                <option value="pending">pending</option>
                <option value="deleted">rejected</option>
                <option value="completed">approved</option>
            </select>
        );


        return <div className="support-select">{html}</div>


    }
    reload = (e) => {
        if(e == "reload"){
            this.getData()
        }

    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <DepositINRReject reloadData = {this.reload} record={this.state.currentRecord} />
                    <DepositINRAppove record={this.state.currentRecord} />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mb-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>

                            <h4 className="mt-2 man_hadding mb-3">Deposit INR Request List</h4>
                            {this.showFilterList()}
                            <ReactDatatable
                                config={this.config}
                                records={this.state.FilterRecords ? this.state.FilterRecords : this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                    <ToastContainer />
                    <div class="modal fade supportImageModel" id="supportImageModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-body p-0">
                                    <img src={this.state.Image} alt="noimg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

DepositINR.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(DepositINR);
