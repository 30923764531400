import { fas } from '@fortawesome/free-solid-svg-icons'
import React, { useState, useEffect } from 'react'
import { baseUrl } from '../pages/baseUrl'
import axios from "axios"
import $ from 'jquery';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function AddMarketTokenModal(props) {
    console.log(props);
    const [date, setDate] = useState("")
    const [state, setState] = useState({ name: "", price: "", country_id: "", date_of_live: "", quantity: "", user_limit: "" });
    const [errorState, setErrorState] = useState({})
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [image, setImage] = useState("")
    const [imageData, setImageData] = useState("")
    const [imageUrl, setImageUrl] = useState("")
    const [dateErr, setDateErr] = useState("")
    const [titleErr, setTitleErr] = useState("")
    const [descriptionErr, setDescriptionErr] = useState("")
    const [imageErr, setImageErr] = useState("")
    const [language, setLanguage] = useState("en")
    const [linkErr, setLinkErr] = useState("")
    const [blogLink, setBlogLink] = useState("")


    useEffect(() => {
        setErrorState({})
        
    }, [])


    const handleChange = (e) => {
        let error = {...errorState};
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
        console.log(name);
        console.log(value);
        error[name] = "";
        if(value == "") {
            error[name] = "This field is required"
        }else {
            if(name=="quantity" || name=="user_limit") {
                if(value <= 0) {
                    error[name] = "Please enter valid number"
                }
            }
            else if(name=="name") {
                if(value.length <= 2) {
                    error[name] = "Please enter more then 2 charater."
                }
            }
            else if(name=="price") {
                if (value == 0) {
                    error[name] = "The Field is required";
                }                
                else if (value < 0) {
                    error[name] = "The Price should not be negative";
                }
            }
        }       
        console.log(error);
        setErrorState(error)
    }

    const handleChange1 = (e) => {
        const { name, value } = e.target;
        console.log(name);
        if (name === "date") {
            setDate(value)
            if (value === "") {
                setDateErr("This field is required")
                return false
            }
            setDateErr("")
        }
        if (name === "title") {
            setTitle(value)
            if (value == "") {
                setTitleErr("This field is required")
                return false
            }
            setTitleErr("")
        }
        if (name === "lang") {
            setLanguage(value)
        }
        if (name === "link") {
            let reg = new RegExp("^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");


            setBlogLink(value)
            if (value == "") {
                setLinkErr("This field is required")
                return false
            } else if (!reg.test(value)) {
                setLinkErr("Please Enter valid Url")
                return false
            }
            setLinkErr("")

        }
        // if(name==="description"){
        //     setDescription(value)
        //     if(value===""){
        //         setDescriptionErr("This field is required")
        //         return false
        //     }
        //     setDescriptionErr("")
        // }
    }
    const closeModal = () => {
        console.log("close");
        setErrorState({})
        setState({ name: "", price: "", country_id: "", date_of_live: "", quantity: "", user_limit: "" })
        setImage("")
        setImageUrl("")
        // setImageData("")
        // setImage("")
        // setImageUrl("")
        // setTitle("")
        // setDescription("")
        // setDateErr("")
        // setTitleErr("")
        // setDescriptionErr("")
        // setLinkErr("")
        // setBlogLink("")
        // setImageErr("")
        // setLanguage("en")
    }

    const handleImage = (e) => {
        let { value } = e.target;

        const icon = e.target.files[0];

        if (value !== "" || value !== undefined) {
            setImage(e.target.files[0]);
            setImageUrl(URL.createObjectURL(e.target.files[0]));
            setImageErr("");
            setErrorState({...errorState,image:""})
        } else {
            setImage(e.target.files[0]);
            setImageUrl("");
            setImageErr("This field is required");
        }
        if (!icon.name.match(/\.(jpg|jpeg|png)$/)) {
            setImageErr("Select valid image format");
            return false;
        }
    };

    const handleSubmit = (e) => {
        console.log(state);
        e.preventDefault()
        // setDescriptionErr("")
        let error = {};
        if (state.name == undefined || state.name === "") {
            error['name'] = "This field is required";
            // setDateErr("This field is required")
           // return false
        }else {
            if(state.name.trim() == ""){
                error['name'] = "The input value is  Invalid";
            }
        }
        
        if (state.price == undefined || state.price === "" || state.price == 0) {
            error['price'] = "This field is required";
            //setTitleErr("This field is required")
           // return false
        }else {
            if (state.price < 0) {
                error['price'] = "The Price should not be negative";
            }
        }
        if (state.country_id == undefined || state.country_id === "") {
            error['country_id'] = "This field is required";
           // setLinkErr("This field is required")
           // return false
        }
        if (state.date_of_live == undefined || state.date_of_live === "") {
            error['date_of_live'] = "This field is required";
            //setDescriptionErr("This field is required")
          //  return false
        }
        if (state.quantity == undefined || state.quantity === "") {
            error['quantity'] = "This field is required";
           // setImageErr("This field is required")
            //return false
        }
        else {
            if (state.quantity < 0) {
                error['quantity'] = "The quantity should not be negative";
            }
        }
        if (state.user_limit == undefined || state.user_limit === "") {
            error['user_limit'] = "This field is required";
            //setImageErr("This field is required")
            //return false
        }
        else {
            if (state.user_limit < 0) {
                error['user_limit'] = "The User limit should not be negative";
            }
        }
        if (image === "") {
            error['image'] = "This field is required";
            //setImageErr("This field is required")
            //return false
        }
        console.log(error);
        setErrorState(error)
        if (Object.keys(error).length == 0) {
            let formdata = new FormData
            formdata.append("name", state.name)
            formdata.append("price", state.price)
            formdata.append("country_id", state.country_id)
            formdata.append("symbol", image)
            formdata.append("date_of_live", state.date_of_live)
            formdata.append("quantity", state.quantity)
            formdata.append("user_limit", state.user_limit);
            console.log(formdata);
            axios.post(baseUrl + "/api/add_market_token", formdata).then((res) => {
                if (res.data.status) {
                    setState({ name: "", price: "", country_id: "", date_of_live: "", quantity: "", user_limit: "" })
                    props.setRefresh(!props.refresh)
                    $('#add-user-modal').modal('hide');
                    closeModal()
                }
            }).catch((err) => {

            })
        }
    }

    return (
        <div>
            <div className="modal fade" id="add-user-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Market Token</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={closeModal}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <form id="update-user" onSubmit={handleSubmit}>
                                <div className='mb-3'>
                                    <label className='form-label'>Token Name:</label>
                                    <input type='text' name="name" value={state.name} onChange={handleChange} className='form-control' />
                                    <span style={{ color: "red" }}>{errorState.name}</span>

                                </div>
                                <div className='mb-3'>
                                    <label className='form-label'>Price:</label>
                                    <input type='number' name="price" value={state.price} onChange={handleChange} className='form-control' />
                                    <span style={{ color: "red" }}>{errorState.price}</span>
                                </div>
                                <div className='mb-3'>
                                    <label className='form-label'>Country:</label>
                                    <select onChange={handleChange} value={state.country_id} name="country_id" className="form-select w-100 form-control">
                                        <option value="">Select</option>
                                        {props.countryList.map((val, index) =>
                                            <option value={val.id} key={index}>{val.name}</option>
                                        )}
                                        {/* <option value="ko">Korean</option>
                    <option value="rus">Russian</option>
                    <option value="per">Persian</option>
                    <option value="ar">Arabic</option> */}
                                    </select>
                                    <span style={{ color: "red" }}>{errorState.country_id}</span>
                                </div>
                                <div className='mb-3'>
                                    <label className='form-label'>Date of live:</label>
                                    <input type="date" name="date_of_live" value={state.date_of_live} onChange={handleChange} className='form-control' min={new Date().toJSON().slice(0, 10)} />

                                    <span style={{ color: "red" }}>{errorState.date_of_live}</span>
                                </div>

                                <div className='mb-3'>
                                    <label className='form-label'>Available quantity:</label>
                                    <input type='number' name="quantity" value={state.quantity} onChange={handleChange} className='form-control' />

                                    <span style={{ color: "red" }}>{errorState.quantity}</span>
                                </div>
                                <div className='mb-3'>
                                    <label className='form-label'>Per User Limit:</label>
                                    <input type='number' name="user_limit" value={state.user_limit} onChange={handleChange} className='form-control' />

                                    <span style={{ color: "red" }}>{errorState.user_limit}</span>
                                </div>
                                <div className='mb-3'>
                                    <img
                                        style={{ width: "100px" }}
                                        src={imageUrl}
                                        className="img-fluid"
                                        alt=""
                                    />
                                    <label className='form-label'>Image:</label>
                                    <input type="file" name="image" onChange={handleImage} className='form-control' accept="image/png, image/jpeg, image/jpg" value={imageData} />
                                    <span style={{ color: "red" }}>{errorState.image}</span>
                                </div>




                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Close</button>
                            <button
                                form="update-user"
                                type="submit"
                                className="btn btn-primary">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddMarketTokenModal
