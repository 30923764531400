import React, { useState, useEffect } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faPlus } from "@fortawesome/free-solid-svg-icons";
import ReactDatatable from "@mkikets/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import CryptoAddModal from "../partials/CryptoAddModal";
import CryptoUpdateModal from "../partials/CryptoUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import $ from "jquery";
import { baseUrl } from "../../actions/baseUrl";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

function LiquidyManager({ auth, cryptodata }) {
  const history = useHistory();
  const [records, setRecords] = useState([]);
  const [cryptoCheckedBoxes, setCryptoCheckedBoxes] = useState([]);
  const [currentRecord, setCurrentRecord] = useState({
    id: "",
    name: "",
    short_name: "",
    order_sequence: "",
    available_for_loan: "",
    available_for_p2p: "",
    icon: "",
  });

  useEffect(() => {
    if (
      auth.user.cryptoCurrency !== undefined &&
      auth.user.cryptoCurrency !== 1
    ) {
      history.push("/dashboard");
    }
    getData();
  }, []);

  useEffect(() => {
    console.log("cryptodata",cryptodata)
    if (
      cryptodata !== undefined &&
      cryptodata.crypto !== undefined &&
      cryptodata.crypto.data !== undefined &&
      cryptodata.crypto.data.message !== undefined
    ) {
      $("#update-user-modal").modal("hide");
      $("#add-user-modal").modal("hide");
      toast(cryptodata.crypto.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      // Set errors here, if needed.
    }
    getData();
  }, [cryptodata]);

  const getData = () => {
    axios
      .get(baseUrl + "/api/binance-data")
      .then((res) => {
        if (res.status === 200) {
          setRecords(res.data.data);
        }
      })
      .catch();
  };

  const editRecord = (record) => {
    setCurrentRecord({
      id: record.id,
      name: record.name,
      short_name: record.short_name,
      chain: record.chain_type,
      url: record.coin_tron,
      order_sequence: record.order_sequence,
      icon: record.icon,
      is_deposit: record.is_deposit,
      is_withdrawal: record.is_withdrawal,
      is_trade: record.is_trade,
      is_show: record.is_show,
      is_tab: record.is_tab,
    });
  };

  //   const toggleCheckbox = (e, item) => {
  //     if (e.target.checked) {
  //       setCryptoCheckedBoxes([...cryptoCheckedBoxes, item.id]);
  //     } else {
  //       setCryptoCheckedBoxes(cryptoCheckedBoxes.filter((id) => id !== item.id));
  //     }
  //   };

  //   const deleteRecordMgs = (record) => {
  //     confirmAlert({
  //       title: 'Confirm to submit',
  //       message: 'Really want to delete this crypto currency?',
  //       buttons: [
  //         {
  //           label: 'Yes',
  //           onClick: () => deleteRecord(record),
  //         },
  //         {
  //           label: 'No',
  //         },
  //       ],
  //     });
  //   };

  const deleteRecord = (record) => {
    axios
      .post(baseUrl + "/api/crypto-delete", { id: record.id })
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch();
    getData();
  };
  const columns = [
    {
      key: "id",
      text: "#",
      className: "id",
      align: "left",
      sortable: true, 
      cell: (row, index) => index + 1,
    },
    {
      key: "currency",
      text: "Coin Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "balance",
      text: "Coin Balance",
      className: "short_name",
      align: "left",
      sortable: true,
    }
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Crypto Currency List",
    no_data_text: "No record found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <button className="btn btn-link mb-3" id="menu-toggle">
              <FontAwesomeIcon icon={faList} /> Add
            </button>
          
            <h4 className="mt-2 man_hadding mb-3">Binance Coin List</h4>
            <div className="white_box">
            <ReactDatatable
              config={config}
              records={records}
              columns={columns}
              onPageChange={pageChange}
            />
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

LiquidyManager.propTypes = {
  auth: PropTypes.object.isRequired,
  cryptodata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  cryptodata: state.cryptodata,
  records: state.records,
});

export default connect(mapStateToProps)(LiquidyManager);
