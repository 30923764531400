import React, { useState, useEffect } from 'react'
import { useLocation, Link } from "react-router-dom";

import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ReactDatatable from "@mkikets/react-datatable";
import Moment from "moment";
import axios from "axios"
import AddBlogModal from '../partials/AddBlogModal';
import AddMarketTokenModal from '../partials/AddMarketTokenModal';
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { baseUrl } from './baseUrl';
import UpdateMarketTokenModal from '../partials/UpdateMarketTokenModal';

function MarketToken() {
    const [refresh, setRefresh] = useState(false)
    const [currentRecord, setCurrentRecord] = useState({})
    const [record, setRecord] = useState([])
    const [image, setImage] = useState("")
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [countryList, setCountryList] = useState([])

    useEffect(() => {
        getData()
        getCountry()
    }, [refresh])

    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Buy Order List",
        no_data_text: "No user found!",
        button: {
            print: true,
            csv: true,
        },
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };

    const columns = [
        {
            // key: "id",
            text: "#",
            className: "id",
            align: "left",
            sortable: true,
            cell: (row, index) => index + 1,
        },
        {
            key: "symbol",
            text: "Symbol",
            className: "title",
            align: "left",
            cell: record => {
                return (
                    <>
                        <Link to={"/token-detail/"+record.id} className="btn-flat waves-effect">


                            <img
                                src={baseUrl + "/static/market_token/" + record.symbol}
                                className=""
                                style={{ width: '120px' }} />
                        </Link>

                    </>

                );
            }
        },
        {
            key: "name",
            text: "Token Name",
            className: "title",
            align: "left",
            sortable: true,
        },
        {
            key: "price",
            text: "Price",
            className: "title",
            align: "left",
            sortable: true,
        },
        {
            key: "country_name",
            text: "Country",
            className: "title",
            align: "left",
            sortable: true,
        },
        {
            key: "quantity",
            text: "Quantity",
            className: "title",
            align: "left",
            sortable: true,
        },
        {
            key: "request_count",
            text: "Total Request",
            className: "title",
            align: "left",
            sortable: true,
        },
        {
            key: "date_of_live",
            text: "Date of Live",
            className: "title",
            align: "left",
            sortable: true,
        },

        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            sortable: false,
            cell: record => {
                return (
                    <>
                        <button
                            data-toggle="modal"
                            data-target="#update-user-modal"
                            className="btn btn-primary btn-sm"
                            onClick={() => editRecord(record)}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-edit"></i>
                        </button>
                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => deleteRecordMgs(record)}>
                            <i className="fa fa-trash"></i>
                        </button>

                    </>
                );
            }
        }

    ];

    const viewData = (record) => {

    }
    const editRecord = (record) => {

        setCurrentRecord(record)

    }

    const deleteRecordMgs = (record) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Really want to delete this blog?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { deleteRecord(record) }
                },
                {
                    label: 'No'

                }
            ]
        });
    }

    const deleteRecord = (record) => {

        axios
            .post(baseUrl + "/api/delete_market_token", { id: record.id })
            .then(res => {
                if (res.status === 200) {
                    getData()
                    toast("Record Deleted Successfully", {
                        position: toast.POSITION.TOP_CENTER,
                    })

                }
            })
            .catch();

    }



    const getData = () => {
        axios.get(baseUrl + "/api/get_market_token").then((res) => {
            if (res.data.status) {
                setRecord(res.data.data)
            }
            else {
                setRecord([])
            }
        }).catch((err) => {

        })
    }

    const getCountry = () => {
        console.log("getCountry");
        axios.get(baseUrl + "/api/get_country").then((res) => {
            if (res.data.status) {
                setCountryList(res.data.data)
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <div>
            <Navbar />
            <div className="" id="wrapper">
                <Sidebar />

                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <button className="btn btn-link mt-3" id="menu-toggle">
                            <FontAwesomeIcon icon={faList} />
                        </button>

                        <AddMarketTokenModal setRefresh={setRefresh} refresh={refresh} countryList={countryList} />
                        <UpdateMarketTokenModal setRefresh={setRefresh} refresh={refresh} currentRecord={currentRecord} countryList={countryList} />
                        <h4 className="mt-2 man_hadding mb-3">Market Token</h4>
                        <div className='white_box'>
                            <button className="btn btn-primary float-right" data-toggle="modal" data-target="#add-user-modal"><FontAwesomeIcon icon={faPlus} /> Add </button>
                            <ReactDatatable
                                config={config}
                                records={record}
                                columns={columns}

                            />
                        </div>
                    </div>

                    <ToastContainer />
                </div>
                <div className="modal fade" id="view-user-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Market Token View</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <div className='row'>
                                        <div className='col-md-6 mb-3'>
                                            <img className="img-fluid" src={baseUrl + "/static/market_token/" + image} />
                                        </div>
                                        <div className='col-md-12'>
                                            <h5>{title}</h5>
                                            {/* <p>{description}</p> */}
                                            <div dangerouslySetInnerHTML={{ __html: description }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default MarketToken