import { fas } from '@fortawesome/free-solid-svg-icons'
import React, { useState, useEffect } from 'react'
import { baseUrl } from '../pages/baseUrl'
import axios from "axios"
import $ from 'jquery';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast, ToastContainer } from "react-toastify";

function ConfirmModal(props) {
    console.log(props);
    const [date, setDate] = useState("")
    const [state, setState] = useState({ name: "", price: "", country_id: "", date_of_live: "", quantity: "", user_limit: "" });
    const [reason, setReason] = useState('');
    const [errorState, setErrorState] = useState('');





    useEffect(() => {
        // setErrorState({})

    }, [])


    const handleChange = (e) => {
        let error = { ...errorState };
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
        console.log(name);
        error[name] = "";
        if (value == "") {
            error[name] = "This field is required"
        } else {
            if (name == "quantity" || name == "user_limit") {
                if (value <= 0) {
                    error[name] = "Please enter valid number"
                }
            }
            else if (name == "name") {
                if (value.length <= 2) {
                    error[name] = "Please enter more then 2 charater."
                }
            }
        }
        console.log(error);
        setErrorState(error)
    }

    const closeModal = () => {
        console.log("close");
        setErrorState({})
        setState({ name: "", price: "", country_id: "", date_of_live: "", quantity: "", user_limit: "" })
        //   setImage("")
        // setImageUrl("")
        // setImageData("")
        // setImage("")
        // setImageUrl("")
        // setTitle("")
        // setDescription("")
        // setDateErr("")
        // setTitleErr("")
        // setDescriptionErr("")
        // setLinkErr("")
        // setBlogLink("")
        // setImageErr("")
        // setLanguage("en")
    }

    const handleImage = (e) => {
        let { value } = e.target;

        const icon = e.target.files[0];

        if (value !== "" || value !== undefined) {
            //  setImage(e.target.files[0]);
            //  setImageUrl(URL.createObjectURL(e.target.files[0]));
            //  setImageErr("");
        } else {
            //  setImage(e.target.files[0]);
            //  setImageUrl("");
            // setImageErr("This field is required");
        }
        if (!icon.name.match(/\.(jpg|jpeg|png)$/)) {
            // setImageErr("Select valid image format");
            return false;
        }
    };

    const onClose = () => {
        $('#add-user-modal').modal('hide');
    }

    const deleteRecord = () => {
        let record = props.currentRecord;
        record.reason = reason;
        axios
            .post(baseUrl + "/api/token_transaction_action", record)
            .then(res => {
                if (res.status === 200) {     
                    onClose()              
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                    window.location.reload();

                }
            })
            .catch();

    }

    const handleSubmit = (e) => {
        console.log(state.country_id);
        e.preventDefault()
        // setDescriptionErr("")
        let error = {};
        if (state.name == undefined || state.name === "") {
            error['name'] = "This field is required";
            // setDateErr("This field is required")
            // return false
        }
        if (state.price == undefined || state.price === "") {
            error['price'] = "This field is required";
            //setTitleErr("This field is required")
            // return false
        }
        if (state.country_id == undefined || state.country_id === "") {
            error['country_id'] = "This field is required";
            // setLinkErr("This field is required")
            // return false
        }
        if (state.date_of_live == undefined || state.date_of_live === "") {
            error['date_of_live'] = "This field is required";
            //setDescriptionErr("This field is required")
            //  return false
        }
        if (state.quantity == undefined || state.quantity === "") {
            error['quantity'] = "This field is required";
            // setImageErr("This field is required")
            //return false
        }
        if (state.user_limit == undefined || state.user_limit === "") {
            error['user_limit'] = "This field is required";
            //setImageErr("This field is required")
            //return false
        }

        console.log(error);
        setErrorState(error)
        if (Object.keys(error).length == 0) {
            let formdata = new FormData
            formdata.append("name", state.name)
            formdata.append("price", state.price)
            formdata.append("country_id", state.country_id)

            formdata.append("date_of_live", state.date_of_live)
            formdata.append("quantity", state.quantity)
            formdata.append("user_limit", state.user_limit);
            console.log(formdata);
            axios.post(baseUrl + "/api/add_market_token", formdata).then((res) => {
                if (res.data.status) {
                    setState({ name: "", price: "", country_id: "", date_of_live: "", quantity: "", user_limit: "" })
                    props.setRefresh(!props.refresh)
                    $('#add-user-modal').modal('hide');
                    closeModal()
                }
            }).catch((err) => {

            })
        }
    }

    return (
        <div>
        <div className="modal fade" id="add-user-modal">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                   
                    <div className="modal-body">
                    <div className='custom-ui'>
                        <h1>Action Confirm ?</h1>
                        <p>Please provide a reason for this action:</p>
                        <input
                            type="text"
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            placeholder="Enter your reason"
                        />
                        <span>{errorState.reason}</span>
                        <button
                            className='btn btn-primary btn-sm'
                            onClick={() => {
                                if (reason.trim() === '') {
                                    alert('Reason is required');
                                    return;
                                    //  setErrors({...errors,reason:'Reason is required'});
                                    // return;
                                }
                                // Handle submission logic
                                console.log('Reason:', reason);
                                deleteRecord();
                            }}
                        >
                            Confirm
                        </button>
                        <button className='btn btn-secondary btn-sm' onClick={onClose}>Cancel</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </div>

    )
}

export default ConfirmModal
