import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@mkikets/react-datatable'; 
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import CryptoAddModal from "../partials/CryptoAddModal";
import CryptoUpdateModal from "../partials/CryptoUpdateModal";
import { toast, ToastContainer} from "react-toastify";
import Moment from 'moment';
import $ from 'jquery';
import { baseUrl } from "./baseUrl";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
class ContactUs extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                text: "#",
                className: "id",    
                align: "left",
                sortable: true,
                cell: (row, index) => index + 1
            },
            {
                key: "Name",
                text: "Name",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "Email",
                text: "Email",
                className: "short_name",
                align: "left",
                sortable: true,
            },
            {
                key: "Subject",
                text: "Subject",
                className: "is_deposit",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          { record.Subject}
                        </Fragment>
                    );
                }
            },
            {
                key: "Message",
                text: "Message",
                className: "is_withdrawal",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          { record.Message}
                        </Fragment>
                    );
                }
            },
            {
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {Moment(record.createdAt).local().format('lll')}
                        </Fragment>
                    );
                }
            },
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Crypto Currency List",
            no_data_text: 'No record found!',
            button: {
            
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            cryptoCheckedBoxes: [],
            currentRecord: {
                id:'',
                name: '',
                short_name: '',
                available_for_loan: '',
                available_for_p2p: '',
                icon:"",
                address:''
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        if (this.props.auth.user.cryptoCurrency !==undefined && this.props.auth.user.cryptoCurrency !==1 ) {
            this.props.history.push("/dashboard");
        }
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.auth.user.cryptoCurrency !==undefined && this.props.auth.user.cryptoCurrency !==1 ) {
            this.props.history.push("/dashboard");
        }
        if (nextProps.cryptodata !== undefined
            && nextProps.cryptodata.crypto !== undefined
            && nextProps.cryptodata.crypto.data !== undefined
            && nextProps.cryptodata.crypto.data.message !== undefined) {
           
            $('#update-user-modal').modal('hide');
            $('#add-user-modal').modal('hide');
            toast(nextProps.cryptodata.crypto.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            this.setState({ errors: {currencyImage:''}});
        }
        this.getData()
    }

    getData = () => {
       
        axios
            .get(baseUrl+"/api/contact-data")
            .then(res => {
                if (res.status === 200) {
                this.setState({ records: res.data})
                }
            })
            .catch()
    }
    editRecord = (record) => {

    }

    deleteRecordMgs(record) {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Really want to delete this crypto currency?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => this.deleteRecord(record)
              },
              {
                label: 'No'
              
              }
            ]
          });
 

    }

    deleteRecord(record) {
        axios
            .post(baseUrl+"/api/crypto-delete", {id: record.id})
            .then(res => {
                if (res.status === 200) {
                   toast(res.data.message, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                }
            })
            .catch();
        this.getData();
    }
    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
      
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid mb-5">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                            <h4 className="mt-2 man_hadding">Contact Us Query's</h4>
                            <div className="white_box">

                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                            </div>
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

ContactUs.propTypes = {
    auth: PropTypes.object.isRequired,
    cryptodata: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    cryptodata: state.cryptodata,
    records: state.records
});
export default connect(
    mapStateToProps
)(ContactUs);
