import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { BrowserRouter as Router, Route, Switch, Redirect,withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import { addRoleData } from "../../actions/roleActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import $ from 'jquery';
import classnames from "classnames";
import * as myConstList from './baseUrl';
const baseUrl = myConstList.baseUrl;
class AddRole extends Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            id: '',
            email: '',
            amount: '',
            role:'add',
            message: '',
            values: [],
            errors: {}
        };


    }

    componentWillReceiveProps(nextProps) {
        if (this.props.auth.user.roleManager !==undefined && this.props.auth.user.roleManager !==1 ) {
            this.props.history.push("/dashboard");
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.roledata !== undefined
            && nextProps.roledata.role !== undefined
            && nextProps.roledata.role.data !== undefined
            && nextProps.roledata.role.data.message !== undefined
            && nextProps.roledata.role.data.success) {
        //    this.props.history.push("/transaction");
        }

    }
    onChange = e => {
        this.setState({errors: {}});
        this.setState({ [e.target.name]: e.target.value });
    };
    onUpdateBalance = e => {
       
        e.preventDefault();
        var length=8;
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * 
          charactersLength));
       }
        axios
        .post(baseUrl+"/api/balance-update", {role: this.state.role,email: this.state.email,
            amount: this.state.amount,tx_id: result})
        .then(res => {
        
            if (res.data.message) {
           
               toast(res.data.message, {
                   position: toast.POSITION.TOP_CENTER,
               })
               
               
                // this.props.history.push('/transaction');
       
         
        }
        if(res.data.email){
            toast(res.data.email, {
                position: toast.POSITION.TOP_CENTER,
            })
        }
              
        
        })
        .catch();

    };
  
    render() {
        const { errors } = this.state;

        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                            <h3 className="mt-2 man_hadding mb-3">Add/Deduct Balance</h3>
                            <div className="in_page row" >
                                <br />
                                
                                <div className="col-md-8" >
                                <div className="man_white_box" >
                                    <div className=" padding_d" >
                                        <ul className="nav nav_left_tab">
                                            <li className="nav-item">
                                                <a className="active show" data-toggle="tab" href="#details">
                                                    <i className="la la-mobile-phone l_i"></i>
                                                    Add/Deduct Balance
                                                </a>
                                            </li>
                                        </ul>
                                        <hr />
                                        <form noValidate onSubmit={this.onUpdateBalance} id="add-user">
                                            <div className="white_box">
                                                <div className="tab-content ">
                                                    <div className="tab-pane active show" id="details">
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="contestStatus">Type</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <select class="browser-default custom-select" name="role" onChange={this.onChange} >
                                                             
                                                                   <option value="add">Add</option>
                                                                    <option value="deduct">Deduct</option>
                                                                </select>
                                                                <span className="text-danger">{errors.role}</span>
                                                            </div>
                                                        </div>
                                                  
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="email">Email</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <input
                                                                    onChange={this.onChange}
                                                                  
                                                                    error={errors.email}
                                                                    name="email"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.email
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.email}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="amount">Amount</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <input
                                                                    onChange={this.onChange}
                                                                  
                                                                    error={errors.amount}
                                                                    name="amount"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.amount
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.amount}</span>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <button className="btn btn-primary " type="submit">Submit</button>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
                );
  }

}

AddRole.propTypes = {
    addRoleData: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    roledata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
                    auth: state.auth,
                    roledata: state.roledata,
                    errors: state.errors
});
export default connect(
                mapStateToProps,
                {addRoleData}
                )(withRouter(AddRole));
