import React, { useState, useEffect, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@mkikets/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PairAddModal from "../partials/PairAddModal";
import PairUpdateModal from "../partials/PairUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import Moment from 'moment';
import $ from 'jquery';
import * as myConstList from "./baseUrl"
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const baseUrl = myConstList.baseUrl;

function Pair(props) {
    const [state, setState] = useState({ box1: 1 });
    const [records, setRecords] = useState([]);
    const [cryptoList, setCryptoList] = useState([]);
    const [secondCryptoList, setSecondCryptoList] = useState([]);
    const [currentRecord, setCurrentRecord] = useState({
        id: '',
        coin_first_id: '',
        coin_second_id: ''
    });

    const listCount = () => {
        var indents = [];
        for (var i = 1; i <= records.length; i++) {
            indents.push(<option value={i} key={i}> {i} </option>);
        }
        return indents

    };

    const onChange = (e, t, i) => {
       // let tempRecord = [...records]
      //  console.log(e.target.value, " ", i);
       // let preRecord = records.filter((val) => val.pair_order == e.target.value)
      //  console.log(preRecord);
       // const value = e.target.value;
        //if (preRecord.length == 0) {

            axios.post(baseUrl + "/api/pair-data-order-update", { id: t.id, pair_order: parseInt(e.target.value) })
                .then(res => {
                    console.log(res)
                    if (res.data.status) {
                      //  t.pair_order = e.target.value;                     
                       // tempRecord[i].pair_order = value;
                        //setRecords(tempRecord);
                        toast("Position changed successfully.", {
                            position: toast.POSITION.TOP_CENTER,
                        })
                        setTimeout(()=>{
                        window.location.reload();
                        },1000)
                    }
                }).catch((e) => {
                    console.log(e);
                });
        // } else {
        //     toast("Position already in use.", {
        //         position: toast.POSITION.TOP_CENTER,
        //     })
        // }
    }

    const columns = [
        {
            key: "id",
            text: "#",
            className: "id",
            align: "left",
            sortable: true,
            cell: (row, index) => index + 1
        },
        {
            key: "name1",
            text: "Currency Pair Box",
            className: "name",
            align: "left",
            sortable: true,
            cell: record => {
                return (
                    <>
                        <span>{record.name1} /</span>
                        <span>{record.name2}</span>
                    </>
                )
            }
        },
        {
            key: "order",
            text: "Order",
            className: "date",
            align: "left",
            sortable: true,
            cell: (record, index) => {
                return (

                    <>
                        <select
                            className="form-control"
                            id="box1"
                            value={record.pair_order}
                            onChange={(e) => onChange(e, record, index)}
                        >
                            <option value="null">0</option>
                            {
                                listCount()
                            }

                        </select>
                    </>

                );
            }
        },
        {
            key: "created",
            text: "Date",
            className: "date",
            align: "left",
            sortable: true,
            cell: (record, i) => {
                return (
                    <Fragment key={i}>
                        {Moment(record.created).format('lll')}
                    </Fragment>
                );
            }
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            sortable: false,
            cell: record => {
                return (
                    <Fragment>
                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => deleteRecordMgs(record)}
                            disabled={record.is_open === 1 ? true : false}>
                            <i className="fa fa-trash"></i>
                        </button>
                    </Fragment>
                );
            }
        }
    ];

    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Pair",
        no_data_text: 'No user found!',
        button: {
            print: true,
            csv: true
        },
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };

    useEffect(() => {
        // Check user permissions here and handle redirection if needed
        getData();
    }, []);

    useEffect(() => {
        if (props.auth.user.pairManagement !== undefined && props.auth.user.pairManagement !== 1) {
            props.history.push("/dashboard");
        }
    }, [props.auth.user.pairManagement, props.history]);

    useEffect(() => {
        if (props.pairdata !== undefined
            && props.pairdata.pair !== undefined
            && props.pairdata.pair.data !== undefined
            && props.pairdata.pair.data.message !== undefined) {
            if (props.pairdata.pair.data.success) {
                hideModals();
                toast(props.pairdata.pair.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                getData();
            } else {
                toast(props.pairdata.pair.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
    }, [props.pairdata]);

    const getData = () => {
        axios
            .get(baseUrl + "/api/pair-data")
            .then(res => {
                console.log(res.data);
                setRecords(res.data);
            })
            .catch();

        axios
            .get(baseUrl + "/api/crypto-data")
            .then(res => {
                setCryptoList(res.data);
            })
            .catch();

        axios
            .get(baseUrl + "/api/crypto-data-by-tab")
            .then(res => {
                setSecondCryptoList(res.data);
            })
            .catch();
    }

    const editRecord = (record) => {
        setCurrentRecord({
            id: record.id,
            coin_first_id: record.coin_first_id,
            coin_second_id: record.coin_second_id,
        });
    }

    const deleteRecordMgs = (record) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Really want to delete this pair?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteRecord(record)
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const deleteRecord = (record) => {
        axios
            .post(baseUrl + "/api/pair-delete", { _id: record.id })
            .then(res => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch();
        getData();
    }

    const pageChange = (pageData) => {
        console.log("OnPageChange", pageData);
    }

    const hideModals = () => {
        $('#add-user-modal').modal('hide');
        $('#update-user-modal').modal('hide');
    }

    return (
        <div>
            <Navbar />
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <PairAddModal cryptoList={cryptoList} secondCryptoList={secondCryptoList} />
                <PairUpdateModal record={currentRecord} cryptoList={cryptoList} secondCryptoList={secondCryptoList} />
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>

                        <h4 className="mt-2 man_hadding mb-3">Pair Management </h4>
                        <div className="white_box">
                            <button className="btn btn-primary float-right " data-toggle="modal" data-target="#add-user-modal"><FontAwesomeIcon icon={faPlus} /> Add </button>

                            <ReactDatatable
                                config={config}
                                records={records}
                                columns={columns}
                                onPageChange={pageChange}
                            />
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    );
}

Pair.propTypes = {
    auth: PropTypes.object.isRequired,
    pairdata: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    pairdata: state.pairdata,
    records: state.records
});

export default connect(
    mapStateToProps
)(Pair);
