import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { Link } from "react-router-dom";
import Moment from "moment";
import axios from "axios";
import { VictoryPie } from "victory";
import { baseUrl } from "../../actions/baseUrl";
import pairicon from "../images/pair-icon.png";
import depositicon from "../images/deposit-icon.png";
import kyccompleteicon from "../images/kyc-complete-icon.png";
import kycpendingicon from "../images/kyc-pending-icon.png";
import supporticon from "../images/support-icon.png";
import transactionicon from "../images/transaction-icon.png";
import withdrawalicon from "../images/withdrawal-icon.png";
import CanvasJSReact from "../canvasjs/canvasjs.react";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function Dashboard({ auth, logoutUser }) {
  const [state, setState] = useState({
    balance: 0,
    upcomingTotal: 0,
    pastTotal: 0,
    liveTotal: 0,
    loginHostory: [],
    userTotal: 0,
    pendingTotal: 0,
    completeTotal: 0,
    supportotal: 0,
    errors: {},
    cryptoDataTotal: "",
    totalRegisterUser: [],
  });

  const [coinState, setCoinState] = useState([]);

  const getData = () => {
    const { user } = auth;
    console.log("manageusers", auth);
    axios
      .post(baseUrl + "/api/login-history-data", { _id: user.id })
      .then((res) => {
        console.log("loginHistory" + res);
        setState((prevState) => ({ ...prevState, loginHostory: res.data }));
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(baseUrl + "/api/getUserTotal")
      .then((res) => {
        setState((prevState) => ({ ...prevState, userTotal: res.data.total }));
      })
      .catch();
    axios
      .get(baseUrl + "/api/getPenddingTotal")
      .then((res) => {
        setState((prevState) => ({ ...prevState, pendingTotal: res.data.total }));
      })
      .catch();
    axios
      .get(baseUrl + "/api/getCompleteTotal")
      .then((res) => {
        setState((prevState) => ({ ...prevState, completeTotal: res.data.total }));
      })
      .catch();
    axios
      .get(baseUrl + "/api/getSupportTotal")
      .then((res) => {
        setState((prevState) => ({ ...prevState, supportotal: res.data.total }));
      })
      .catch();
    axios
      .get(baseUrl + "/api/crypto-data")
      .then((res) => {
        setState((prevState) => ({ ...prevState, cryptoDataTotal: res.data.length }));
      })
      .catch();
    axios
      .get(baseUrl + "/api/pair-data")
      .then((res) => {
        setState((prevState) => ({ ...prevState, pairDataTotal: res.data.length }));
      })
      .catch();
    axios
      .get(baseUrl + "/api/userdepositHistory")
      .then((res) => {
        console.log("====> ", res);
        setState((prevState) => ({ ...prevState, depositeHistoryTotal: res.data.length }));
      })
      .catch((err) => {
        console.log("====> ", err);
      });
    axios
      .get(baseUrl + "/api/total-withdraw-amount")
      .then((res) => {
        setState((prevState) => ({ ...prevState, withdrawalHistoryTotal: res.data[0].totalWithdraw }));
      })
      .catch();
    axios
      .get(baseUrl + "/api/total_register_user")
      .then((res) => {
        console.log("total_register_user", res.data);
        setState((prevState) => ({ ...prevState, totalRegisterUser: res.data }));
      })
      .catch();

      axios
      .get(baseUrl + "/api/coin_pairs_total_amount")
      .then((res) => {
        console.log("coin_pairs_total_amount", res.data);
        setCoinState(res.data);
      })
      .catch();
  };

  useEffect(() => {
    getData();
  }, []);

  const onLogoutClick = (e) => {
    e.preventDefault();
    logoutUser();
  };

  const canvasFirstHTML = () => {
    const html = [];
    var dataPie = [
      { y: state.userTotal, name: "Total User" },
      { y: state.completeTotal, name: "Kyc Complete" },
      { y: state.pendingTotal, name: "Kyc Pending" },
    ];
    console.log("dataPie", dataPie);

    var options = {
      theme: "dark2",
      title: {
        text: "Kyc Record",
        horizontalAlign: "left",
        fontColor: "#026555",
        fontFamily: "Roboto",
        fontSize: 20,
      },
      elements: {
        line: {
          tension: 0, // disables bezier curves
        },
      },

      animationEnabled: true,
      data: [
        {
          type: "doughnut",
          showInLegend: true,
          indexLabel: "{name}: {y}",
          yValueFormatString: "####",
          dataPoints: dataPie,
        },
      ],

      backgroundColor: "transparent",
      color: "#fff",
    };
    html.push(<CanvasJSChart options={options} />);
    return html;
  };

  const canvasSecondHTML = () => {
    const dataPai = [];
    state.totalRegisterUser.map((data, i) => {
      dataPai.push({
        x: new Date(data.registeredDate),
        y: data.id,
      });
    });
    const options = {
      animationEnabled: true,
      exportEnabled: true,
      theme: "dark2",
      title: {
        text: "User Registration",
        horizontalAlign: "left",
        fontColor: "#026555",
        fontFamily: "Roboto",
        fontSize: 20,
      },
      axisY: {
        includeZero: true,
      },
      toolTip: {
        enabled: true,
      },
      axisX: {
        lineThickness: 0,
        tickLength: 0,
        labelFormatter: function (e) {
          return "";
        },
      },
      axisY: {
        lineThickness: 0,
        gridThickness: 0,
        tickLength: 0,
        labelFormatter: function (e) {
          return "";
        },
      },

      exportEnabled: false,
      backgroundColor: "transparent",
      data: [
        {
          type: "splineArea",
          indexLabelFontColor: "#5A5757",
          indexLabelPlacement: "outside",
          dataPoints: dataPai,
        },
      ],
    };

    const html = [];
    html.push(<CanvasJSChart options={options} />);
    return html;
  };

  const showLoginHistoryHtml = () => {
    const html = [];
    state.loginHostory.map(function (value, i) {
      html.push(
        <>
          {" "}
          <tr key={i}>
            <td>{i + 1}</td>
            <td>{value.email}</td>
            <td>{value.ip}</td>
            <td>{value.cityName}</td>
            <td>{value.region}</td>
            <td>{value.countryName}</td>
            <td>{Moment(value.date).format("lll")}</td>
          </tr>
        </>
      );
    });
    return html;
  };

  const { user } = auth;

  return (
    <div>
      <div className="d-flex" id="wrapper">
        {console.log("pairDataTotal", state.pairDataTotal)}
        <Sidebar />
        <div id="page-content-wrapper">
          <Navbar />
          <div className="container-fluid">
            <button className="btn btn-link mt-2" id="menu-toggle">
              <FontAwesomeIcon icon={faList} />
            </button>
            <h4 className="mt-2 man_hadding">Dashboard</h4>
            <div className="row px-2">
              {user.cryptoCurrency == true && (
                <div className="col-lg-3 col-md-6 col-sm-6 col-12 p-sm-2">
                  <div className="card text-white d_box">
                    <Link to="/crypto_currency" className="card-body">
                      <h6>Currency</h6>
                      <h4>{state.cryptoDataTotal}</h4>
                      <div className="d_icon">
                        <i className="fa fa-database"  ></i>

                      </div>
                    </Link>
                  </div>
                </div>
              )}
              {user.pairManagement == true && (
                <div className="col-lg-3 col-md-6 col-sm-6 col-12 p-sm-2">
                  <div className="card text-white d_box">
                    <Link to="/pair_management" className="card-body">
                      <h6>Pair</h6>
                      <h4>{state.pairDataTotal}</h4>
                      <div className="d_icon">
                        <i className="fa fa-delicious"  ></i>

                      </div>
                    </Link>
                  </div>
                </div>
              )}
              {user.suportManagement == true && (
                <div className="col-lg-3 col-md-6 col-sm-6 col-12 p-sm-2">
                  <div className="card d_box">
                    <Link to="/suport" className="card-body">
                      <h6>Support</h6>
                      <h4>{state.supportotal}</h4>
                      <div className="d_icon">
                        <i className="fa fa-headphones"  ></i>
                      </div>
                    </Link>
                  </div>
                </div>
              )}
              {/* {user.manageUsers == true && (
                  <div className="col-lg-3 col-md-6 col-sm-6 col-12 p-sm-2">
                    <div className="card bg-primary text-white d_box">
                      <Link to="/users" className="card-body">
                        <h6>Users</h6>
                        <h4>{state.userTotal}</h4>
                        <div className="d_icon">
                          <i class="fa fa-users" aria-hidden="true"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                )} */}
              {/* {user.verifyPending == true && (
                  <div className="col-lg-3 col-md-6 col-sm-6 col-12 p-sm-2">
                    <div className="card bg-success d_box no-click">
                      <Link to="#" className="card-body">
                        <h6>KYC Pending</h6>
                        <h4>{state.pendingTotal}</h4>
                        <div className="d_icon">
                          <img src={kycpendingicon}/>
                        </div>
                      </Link>
                    </div>
                  </div>
                )}
                {user.verifyComplete == true && (
                  <div className="col-lg-3 col-md-6 col-sm-6 col-12 p-sm-2">
                    <div className="card bg-info d_box ">
                      <Link to="/users-complite-record" className="card-body">
                        <h6>KYC Complete</h6>
                        <h4>{state.completeTotal}</h4>
                        <div className="d_icon">
                          <img src={kyccompleteicon}/>
                        </div>
                      </Link>
                    </div>
                  </div>
                )} */}
              {/* {user.depositFee == true && (
                  <div className="col-lg-3 col-md-6 col-sm-6 col-12 p-sm-2">
                    <div className="card bg-info d_box ">
                      <Link to="/deposit-inr" className="card-body">
                        <h6>Total Deposit</h6>
                        <h4>₹{state.depositeHistoryTotal}</h4>
                        <div className="d_icon">
                          <img src={depositicon} />
                        </div>
                      </Link>
                    </div>
                  </div>
                )} */}
              {user.fundRequest == true && (
                <div className="col-lg-3 col-md-6 col-sm-6 col-12 p-sm-2">
                  <div className="card  d_box ">
                    <Link to="/coinWithdrawal" className="card-body">
                      <h6>Total Withdrawal </h6>
                      <h4>{state.withdrawalHistoryTotal ? state.withdrawalHistoryTotal : "0.00"}</h4>
                      <div className="d_icon">
                        <i className="fa fa-usd" ></i>
                      </div>
                    </Link>
                  </div>
                </div>
              )}
              {/* {user.fundRequest == true && (
                  <div className="col-lg-3 col-md-6 col-sm-6 col-12 p-sm-2">
                    <div className="card bg-info d_box no-click">
                      <Link to="#" className="card-body">
                        <h6>Total Transaction</h6>
                        <h4>{state.completeTotal}</h4>
                        <div className="d_icon">
                          <img src={transactionicon} />
                        </div>
                      </Link>
                    </div>
                  </div>
                )} */}
            </div>
            <h4 className="mt-2 man_hadding">Coins ({coinState.length})</h4>
            <div className="row px-2">
              
              {coinState && (
                coinState.map((val)=>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12 p-sm-2">
                  <div className="card text-white">
                    <Link to={"/coin_user_list/"+val.cryptocoin_id} className="card-body" style={{color:"white"}}>
                      <h6>{val.name}</h6>
                      <h5>{val.coin_amount}</h5>
                      {/* <div className="d_icon">
                        <i className="fa fa-database"  ></i>

                      </div> */}
                    </Link>
                  </div>
                </div>
                )
              )}
            </div>
            <div className="row mt-5">
              <div className="col-md-8">
                <div className="white_box">{canvasSecondHTML()}</div>
              </div>
              <div className="col-md-4">
                <div className="white_box">{canvasFirstHTML()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Dashboard);
