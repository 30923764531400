import { fas } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { baseUrl } from '../pages/baseUrl'
import axios from "axios"
import $ from 'jquery';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
function AddTradingStepsModal(props) {
  const [date, setDate] = useState("")
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [imageData, setImageData] = useState("")
  const [image, setImage] = useState("")
  const [imageUrl, setImageUrl] = useState("")
  const [dateErr, setDateErr] = useState("")
  const [titleErr, setTitleErr] = useState("")
  const [descriptionErr, setDescriptionErr] = useState("")
  const [imageErr, setImageErr] = useState("")
  const [language, setLanguage] = useState("en")
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, ' ', value);
    if (name === "date") {
      setDate(value)
      if (value === "") {
        setDateErr("This field is required")
        return false
      }
      setDateErr("")
    }
    if (name === "title") {
      setTitle(value)
      if (value == "") {
        setTitleErr("This field is required")
        return false
      }
      setTitleErr("")
    }
    if (name === "description") {
      setDescription(value)
      if (value === "") {
        setDescriptionErr("This field is required")
        return false
      }
      setDescriptionErr("")
    }
    if (name === "lang") {
      setLanguage(value)     
    }
  }
  const handleImage = (e) => {
    let { value } = e.target;

    const icon = e.target.files[0];

    if (value !== "" || value !== undefined) {
      setImage(e.target.files[0]);
      setImageUrl(URL.createObjectURL(e.target.files[0]));
      setImageErr("");
    } else {
      setImage(e.target.files[0]);
      setImageUrl("");
      setImageErr("This field is required");
    }
    if (!icon.name.match(/\.(jpg|jpeg|png)$/)) {
      setImageErr("Select valid image format");
      return false;
    }
  };

  const onChange = (e) => {
    setLanguage(e.target.value)
  }

  const handleSubmit = (e) => {
    setButtonDisabled(true)
    e.preventDefault()
    if (description === "") {
      setDescriptionErr("This field is required")
      return false
    }
    if (image === "") {
      setImageErr("This field is required")
      return false
    }
    let formdata = new FormData
    formdata.append("description", description)
    formdata.append("title", title)
    formdata.append("image", image)
    formdata.append("lang", language)
    axios.post(baseUrl + "/api/add_trading_steps", formdata).then((res) => {
      if (res.data.status) {
        setButtonDisabled(false)
        props.setRefresh(!props.refresh)
        $('#add-user-modal').modal('hide');
        setDate("")
        setTitle("")
        setDescription("")
        setImage("")
        setImageUrl("")
        setLanguage("")

      }
    }).catch((err) => {

    })
  }
  const closeModal = () => {
    console.log("close");
    setImageData("")
    setImageUrl("")
    setTitle("")
    setDescription("")
    setLanguage("")
  }
  return (
    <div>
      <div className="modal fade" id="add-user-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Trading Steps</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
              <form id="update-user" onSubmit={handleSubmit}>
                <div className='mb-3'>
                  <label className='form-label'>Language:</label>
                  <select onChange={onChange} value={language} name="lang" className="form-select w-100 form-control">
                    <option value="en">English</option>
                    <option value="ko">Korean</option>
                    <option value="rus">Russian</option>
                    <option value="per">Persian</option>
                    <option value="ar">Arabic</option>
                  </select>
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Title:</label>
                  <input type="text" name="title" value={title} onChange={handleChange} className='form-control' />
                  <span style={{ color: "red" }}>{titleErr}</span>
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Description:</label>
                  <textarea name="description" value={description} onChange={handleChange} className='form-control' />
                  <span style={{ color: "red" }}>{descriptionErr}</span>
                </div>

                <div className='mb-3'>
                  <img style={{ width: "100px" }} src={imageUrl} className="img-fluid" alt="" />
                  <label className='form-label'>Image:</label>
                  <input type="file" name="image" onChange={handleImage} className='form-control' accept="image/png, image/jpeg, image/jpg" value={imageData} />
                  <span style={{ color: "red" }}>{imageErr}</span>
                </div>




              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Close</button>
              <button
                disabled={buttonDisabled}
                form="update-user"
                type="submit"
                className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddTradingStepsModal
