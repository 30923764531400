import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { BrowserRouter as Router, Route, Switch,Redirect } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import { addContest } from "../../actions/contestActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";
// import UserUpdateModal from "../partials/UserUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import $ from 'jquery';
import classnames from "classnames";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as myConstList from "./baseUrl"

const baseUrl = myConstList.baseUrl
class AddContest extends Component {

  constructor(props) {
    super(props);
    this.state = {
      records: [],
      id: '',
      logo: '',
      contestName: '',
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      entryFee: '',
      virtualFund: '',
      tstatus: '',
      winningAmount: '',
      description: '',
      invalidImage: '',
      contestStatus: '',
      pair_id: [],
      message: '',
      values: [],
      errors: {}
    };

    this.getData = this.getData.bind(this);
  }
  componentDidMount() {
    this.getData();
  };
  componentWillReceiveProps(nextProps) {
      if (nextProps.errors) {
        this.setState({
          errors: nextProps.errors
        });
      }
      if (nextProps.auth !== undefined
        && nextProps.auth.user !== undefined
        && nextProps.auth.user.data !== undefined
        && nextProps.auth.user.data.message !== undefined
        && nextProps.auth.user.data.success) {
         
        toast(nextProps.auth.user.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
       
        this.props.history.push("/contest_management");
  
  
      }
  
    }

  getData() {
    axios
      .post(baseUrl + "/api/pair-data")
      .then(res => {
        this.setState({ records: res.data })
      })
      .catch()
  }
 
  onChange = e => {
    if (e.target.id === 'contestName') {
      this.setState({ contestName: e.target.value,
        errors: {
          contestName:''
        } });
      
    }
    if (e.target.id === 'entryFee') {
      this.setState({ entryFee: e.target.value,
        errors: {
          entryFee:''
        } });
    }
    if (e.target.id === 'contestStatus') {
      this.setState({ contestStatus: e.target.value,
        errors: {
          contestStatus:''
        } });
    }
    if (e.target.id === 'virtualFund') {
      this.setState({ virtualFund: e.target.value,
        errors: {
          virtualFund:''
        } });
    }
    if (e.target.id === 'startTime') {
      this.setState({ startTime: e.target.value,
        errors: {
          startTime:''
        } });
    }
    if (e.target.id === 'endTime') {
      this.setState({ endTime: e.target.value,
        errors: {
          endTime:''
        } });
    }
    if (e.target.id === 'winningAmount') {
      this.setState({ winningAmount: e.target.value,
        errors: {
          winningAmount:''
        } });
    }
    if (e.target.id === 'tstatus') {
      this.setState({ values: '' });
      for (var i = 0; i < e.target.value; i++) {
        this.addClick();
      }
      this.setState({ tstatus: e.target.value,
        errors: {
          tstatus:''
        } });
    }
    if (e.target.name === 'contestLogo') {
      var contestLogo = e.target.files[0];
      var fileSize = e.target.files[0].size;
      if (fileSize > 1000000) {
        this.setState({ invalidImage: "Maximum 1 MB Image allowed.", firstSubmitIsDisabled: true, userUploadImage: false });
        return false;
      }
      console.log(this.state.invalidImage);
      if (!contestLogo.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        this.setState({ invalidImage: "Please select valid Front image jpeg,png,gif.", firstSubmitIsDisabled: true, userUploadImage: false });
        return false;
      }
      console.log(contestLogo);
      this.setState({
        logo: contestLogo,
        errors: {
          logo:''
        }

      });
    }
    this.setState({ [e.target.id]: e.target.value });
  };
  createUI() {
    console.log(this.state.values);
    return this.state.values.map((el, i) =>
      <div key={i} className="row mt-2">

        <div className="col-md-3">
          <label htmlFor="entryFee">Level {i + 1} </label>
        </div>
        <div className="col-md-9">
          <input type="text" id={"l" + (i + 1)} value={el || ''} onChange={this.handleChange.bind(this, i)} />
        </div>

      </div>
    )
  }
  createPairUI() {

    return this.state.records.map((el, i) =>
      <div key={i} className="row mt-2">
        <div className="col-md-3">
          <label htmlFor="entryFee"> {i + 1 + ") "}  {el.box1 + " <=> "}  {el.box2} </label>
        </div>
        <div className="col-md-9">
          <input type="checkbox" id={"p" + (i + 1)} value={el._id} onChange={this.handleChangePair.bind(this, i)} />
        </div>
      </div>
    )
  }
  addClick() {
    this.setState(prevState => ({ values: [...prevState.values, ''] }))
  }

  handleChange(i, event) {
    let values = [...this.state.values];
    values[i] = event.target.value;
    let sum = 0;

    for (let num of values) {
      if (num) {

        sum += parseFloat(num)
      }
    }
    if (sum > this.state.winningAmount) {
    } else {
      this.setState({ values,
        errors: {
          levels:''
        } });
    }
  }
  handleChangePair(i, event) {
    let pair_id = [...this.state.pair_id];
    pair_id[i] = event.target.value;


    this.setState({ pair_id });
  }
  handleCommentChange = (event, editor) => {

    var data = editor.getData();
    if (data) {
      this.setState({ description: data,
        errors: {
          description:''
        } });
    }

  };

  onContestAdd = e => {
    e.preventDefault();
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    const formData = new FormData();
    formData.append('logo', this.state.logo);
    formData.append('contestName', this.state.contestName);
    formData.append('startTime', this.state.startTime);
    formData.append('startDate', this.state.startDate);
    formData.append('endDate', this.state.endDate);
    formData.append('endTime', this.state.endTime);
    formData.append('entryFee', this.state.entryFee);
    formData.append('virtualFund', this.state.virtualFund);
    formData.append('winningLevel', this.state.tstatus);
    formData.append('winningAmount', this.state.winningAmount);
    formData.append('description', this.state.description);
    formData.append('pair_id', this.state.pair_id);
    formData.append('levels', this.state.values);
    formData.append('contestStatus', this.state.contestStatus);
    this.props.addContest(formData, config);


  };
 
  render() {
    const { errors } = this.state;
    
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
              <h1 className="mt-2 text-primary">Add Contest</h1>
              <div className="in_page row" >
                <br />
                <div className="col-md-2" ></div>
                <div className="col-md-8" >
                  <div className=" padding_d" >
                    <ul className="nav nav_left_tab">
                      <li className="nav-item">
                        <a className="active show" data-toggle="tab" href="#details">
                          <i className="la la-mobile-phone l_i"></i>
                          Details
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="" data-toggle="tab" href="#prizeMoney">
                          <i className="la la-user l_i"></i>
                          Prize Money
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="" data-toggle="tab" href="#paid">
                          <i className="la la-map-marker l_i"></i>
                          Paid
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="" data-toggle="tab" href="#contestRule">
                          <i className="la la-map-marker l_i"></i>
                          Contest Rule
                        </a>
                      </li>
                    </ul>
                    <hr />
                    <form noValidate onSubmit={this.onContestAdd} id="add-user">
                      <div className="white_box">
                        <div className="tab-content ">

                          <div className="tab-pane active show" id="details">

                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label htmlFor="contestName">Contest Name</label>
                              </div>
                              <div className="col-md-9">
                                <input
                                  onChange={this.onChange}
                                  value={this.state.contestName}
                                  error={errors.contestName}
                                  id="contestName"
                                  type="text"
                                  className={classnames("form-control", {
                                    invalid: errors.contestName
                                  })}
                                />
                                <span className="text-danger">{errors.contestName}</span>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label htmlFor="contestLogo">Logo</label>
                              </div>
                              <div className="col-md-9">
                                <input
                                  onChange={this.onChange}
                                  value={this.state.contestLogo}
                                  id="contestLogo"
                                  name="contestLogo"
                                  type="file"

                                />
                                <span className="text-danger">{this.state.invalidImage}</span>
                                <span className="text-danger">{errors.logo}</span>
                              </div>
                            </div>

                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label htmlFor="entryFee">Entry Fee</label>
                              </div>
                              <div className="col-md-9">
                                <input
                                  onChange={this.onChange}
                                  value={this.state.entryFee}
                                  id="entryFee"
                                  type="text"
                                  error={errors.entryFee}
                                  className={classnames("form-control", {
                                    invalid: errors.entryFee
                                  })} />
                                <span className="text-danger">{errors.entryFee}</span>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label htmlFor="contestStatus">Contest Status</label>
                              </div>
                              <div className="col-md-9">
                                <select class="browser-default custom-select" id="contestStatus" onChange={this.onChange} >
                                  <option value="">Please Select</option>
                                  <option value="Upcoming">Upcoming</option>
                                  <option value="Live">Live</option>
                                  <option value="Past">Past</option>


                                </select>
                                <span className="text-danger">{errors.contestStatus}</span>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label htmlFor="virtualFund">Virtual Fund</label>
                              </div>
                              <div className="col-md-9">
                                <input
                                  onChange={this.onChange}
                                  value={this.state.virtualFund}
                                  id="virtualFund"
                                  type="text"
                                  error={errors.virtualFund}
                                  className={classnames("form-control", {
                                    invalid: errors.virtualFund
                                  })} />
                                <span className="text-danger">{errors.virtualFund}</span>
                              </div>

                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label htmlFor="startDate">Start Time</label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  autoComplete={''}
                                  onChange={this.onChange}
                                  value={this.state.startDate}
                                  error={errors.startDate}
                                  id="startDate"
                                  type="date"
                                  className={classnames("form-control", {
                                    invalid: errors.startDate
                                  })}
                                />
                                <span className="text-danger">{errors.startDate}</span>
                              </div>
                              <div className="col-md-2">
                                <input
                                  autoComplete={''}
                                  onChange={this.onChange}
                                  value={this.state.startTime}
                                  error={errors.startTime}
                                  id="startTime"
                                  type="time"
                                  className={classnames("form-control", {
                                    invalid: errors.startTime
                                  })}
                                />
                                <span className="text-danger">{errors.startTime}</span>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label htmlFor="endTime">End Time</label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  autoComplete={''}
                                  onChange={this.onChange}
                                  value={this.state.endDate}
                                  id="endDate"
                                  type="date"
                                  className={classnames("form-control", {
                                    invalid: errors.endDate
                                  })}
                                />
                                <span className="text-danger">{errors.endDate}</span>
                              </div>
                              <div className="col-md-2">
                                <input
                                  autoComplete={''}
                                  onChange={this.onChange}
                                  value={this.state.endTime}
                                  id="endTime"
                                  type="time"
                                  className={classnames("form-control", {
                                    invalid: errors.endTime
                                  })}
                                />
                                <span className="text-danger">{errors.endTime}</span>
                              </div>
                            </div>
                          </div>
                          <div className="tab-pane" id="prizeMoney">
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label htmlFor="winningAmount">Winning Amount</label>
                              </div>
                              <div className="col-md-9">
                                <input
                                  onChange={this.onChange}
                                  value={this.state.winningAmount}
                                  id="winningAmount"
                                  type="text"
                                  className="form-control"
                                />
                                <span className="text-danger">{errors.winningAmount}</span>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label htmlFor="entryFee">Winning Level</label>
                              </div>
                              <div className="col-md-9">
                                <select class="browser-default custom-select" id="tstatus" onChange={this.onChange} value={this.state.tstatus}>

                                  <option value="1">Level 1</option>
                                  <option value="2">Level 2</option>
                                  <option value="3">Level 3</option>
                                  <option value="4">Level 4</option>
                                  <option value="5">Level 5</option>
                                  <option value="6">Level 6</option>
                                  <option value="7">Level 7</option>
                                  <option value="8">Level 8</option>
                                  <option value="9">Level 9</option>
                                  <option value="10">Level 10</option>
                                  <option value="11">Level 11</option>
                                  <option value="12">Level 12</option>
                                  <option value="13">Level 13</option>
                                  <option value="14">Level 14</option>
                                  <option value="15">Level 15</option>
                                  <option value="16">Level 16</option>
                                  <option value="17">Level 17</option>
                                  <option value="18">Level 18</option>
                                  <option value="19">Level 19</option>
                                  <option value="20">Level 20</option>
                                </select>
                                <span className="text-danger">{errors.levels}</span>
                              </div>
                            </div>
                            {this.createUI()}
                          </div>
                          <div className="tab-pane" id="paid">
                            {this.createPairUI()}
                          </div>
                          <div className="tab-pane " id="contestRule">
                            <div className="row mt-2">
                              <div className="col-md-12">
                                <label htmlFor="contestName">Write Contest Rule :-</label>
                              </div>
                              <div className="col-md-12">
                                <CKEditor editor={ClassicEditor}
                                  value={this.state.description}
                                  onChange={this.handleCommentChange}
                                  placeholder="Enter Description"
                                  className="form-control form_control_textarea"
                                  data={this.state.description} />
                                <br />
                                <span className="text-danger">{errors.description}</span>
                              </div>
                            </div>
                            <div className="form-group mb-5 mt-2 float-right">
                              <button type="submit" className="btn btn-dark">Submit</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }

}

AddContest.propTypes = {
  addContest: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { addContest }
)(withRouter(AddContest));
