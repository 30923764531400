import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@mkikets/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import * as myConstList from "./baseUrl";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import $ from "jquery";
import UserStakeModal from "../partials/UserStakeModal";
import StakeModal from "../partials/StakeModal";
const baseUrl = myConstList.baseUrl;
class UsersStacking extends Component {
  constructor(props) {
    super(props);
    this.state={
      disable:false
    }

    this.columns = [
      {
        key: "#",
        text: "Id",
        className: "id",
        align: "left",
        sortable: true,
        cell: (row, index) => index + 1,
      },

      {
        key: "user_id",
        text: "User Id",
        className: "name",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <>
              <span>
                {/* {record.country_code == "+91" ? record.mobile_no : record.email}{" "} */}

                {record.email}
              </span>
              {/* <button
                data-toggle="modal"
                data-target="#update-stack-modal"
                className="btn btn-primary btn-sm mr-2"
                onClick={() => this.userDataApi(record)}
                title="User Info"
              >
                <i class="fa fa-info-circle" aria-hidden="true"></i>
              </button> */}
            </>
          );
        },
      },

      {
        key: "stake_id",
        text: "Stake Id",
        className: "name",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <>
              <span>{record.stake_id} </span>
              <button
                data-toggle="modal"
                data-target="#update-fund-modal"
                className="btn btn-primary btn-sm mr-2"
                onClick={() => this.userStakeApi(record)}
                title="Stake Info"
              >
                <i class="fa fa-info-circle" aria-hidden="true"></i>
                {/* <i class="fa fa-stack-exchange" aria-hidden="true"></i> */}
              </button>
            </>
          );
        },
      },
      {
        key: "locked_amount",
        text: "Locked Amount",
        className: "name",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <>
              <span>
                {record.locked_amount} {record.short_name}
              </span>
            </>
          );
        },
      },

      {
        key: "current_amount",
        text: "Current Amount",
        className: "name",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              {record.current_amount} {record.short_name}
            </Fragment>
          );
        },
      },
      {
        key: "start_date",
        text: "Start Date",
        className: "name",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <>
              <span>{record.start_date}</span>
            </>
          );
        },
      },

      {
        key: "end_date",
        text: "End Date",
        className: "name",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <>
              <span>{record.end_date ? record.end_date : "-"}</span>
            </>
          );
        },
      },
      {
        key: "request_status",
        text: "Status",
        className: "name",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <>
              {record.status === "0" ? (
                <span style={{ color: "green" }}> Active </span>
              ) : (
                <span style={{ color: "red" }}> Closed </span>
              )}
            </>
          );
        },
      },
      {
        key: "remark",
        text: "Remark",
        className: "remark",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <>
              {record.remark == "blocked_by_admin" ? (
                <span> Blocked By Admin </span>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
      // {
      //   key: "action",
      //   text: "Action",
      //   className: "action",
      //   width: 100,
      //   align: "left",
      //   sortable: false,
      //   cell: (record) => {
      //     return (
      //       <Fragment>
      //         {record.status == "0" ? (
      //           <button
      //             className="btn btn-danger btn-sm"
      //             onClick={() => this.deleteRecordMgs(record)}
      //             disabled={this.state.disable}
      //           >
      //             Block
      //             {/* <i class="fa fa-lock"></i> */}
      //           </button>
      //         ) : (
      //           ""
      //         )}
      //       </Fragment>
      //     );
      //   },
      // },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "country",
      no_data_text: "No user found!",
      button: {
        excel: true,
        print: true,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
      cryptoList: [],
      userInfo: [],
      stakeInfo: [],
    };

    this.state = {
      currentRecord: {
        id: "",
        name: "",
      },
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  userDataApi(record) {
    let data = {
      _id: record.user_id,
    };

    axios
      .post(baseUrl + "/api/User-By-Id-Data-Stake", data)
      .then((res) => {
        console.log("whats the rep", res.data[0]);
        this.setState({ userInfo: res });
      })
      .catch();
  }

  userStakeApi(record) {
    let data = {
      stake_id: record.stake_id,
    };

    axios
      .post(baseUrl + "/api/getUserStakeById", data)
      .then((res) => {
        this.setState({ stakeInfo: res });
      })
      .catch();
  }

  componentWillReceiveProps(nextProps) {
    console.log("Stacking_componentWillReceiveProps", nextProps);
    if (
      nextProps.stacking !== undefined &&
      nextProps.stacking.pair !== undefined &&
      nextProps.stacking.pair.data !== undefined &&
      nextProps.stacking.pair.data.message !== undefined &&
      nextProps.stacking.pair.data.success
    ) {
      $("#add-user-modal").modal("hide");
      $("#update-user-modal").modal("hide");

      toast(nextProps.stacking.pair.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    // if (
    //   nextProps.stacking !== undefined &&
    //   nextProps.stacking.pair !== undefined &&
    //   nextProps.stacking.pair.data !== undefined &&
    //   nextProps.stacking.pair.data.message !== undefined &&
    //   nextProps.stacking.pair.data.success
    // ) {
    //   $("#add-user-modal").modal("hide");
    //   $("#update-user-modal").modal("hide");

    //   toast(nextProps.stacking.pair.data.message, {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    // }

    this.getData();
  }

  getData() {
    axios
      .get(baseUrl + "/api/getAllUsersStacking")
      .then((res) => {
        console.log("res.data", res.data);
        this.setState({ records: res.data });
      })
      .catch();
    axios
      .get(baseUrl + "/api/crypto-data")
      .then((res) => {
        this.setState({ cryptoList: res.data });
      })
      .catch();
  }

  // editRecord(record) {
  //   this.setState({ currentRecord: record });
  // }

  deleteRecordMgs(record) {
    this.setState({disable:true})
    confirmAlert({
      title: "Confirm to submit",
      message: "Really want to stop this user stake?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteRecord(record),
        },
        {
          label: "No",
        },
      ],
    });
  }

  deleteRecord(record) {
    axios
      .post(baseUrl + "/api/stacking-user-delete", { id: record.id })
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.getData();
          setTimeout(() => {
          
            this.setState({disable:false})
          }, 3000);
        }
      })
      .catch();
    
  }

  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <UserStakeModal userInfo={this.state.userInfo} />
          <StakeModal stakeInfo={this.state.stakeInfo} />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <button className="btn btn-link mt-3" id="menu-toggle">
                <FontAwesomeIcon icon={faList} />
              </button>

              <h4 className="mt-2 man_hadding mb-3">Users Staking List</h4>
              <div className="white_box">

              <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                onPageChange={this.pageChange.bind(this)}
              />
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

UsersStacking.propTypes = {
  auth: PropTypes.object.isRequired,
  comdata: PropTypes.object.isRequired,
  stacking: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  comdata: state.comdata,
  records: state.records,
  stacking: state.stacking,
});

export default connect(mapStateToProps)(UsersStacking);
