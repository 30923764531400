import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@mkikets/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

import { toast, ToastContainer} from "react-toastify";
import * as myConstList from './baseUrl';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import $ from 'jquery';
import DepositFeeAddModal from "../partials/DepositFeeAddModal";
import DepositFeeUpdateModal from "../partials/DepositFeeUpdateModal";
const baseUrl = myConstList.baseUrl;
class DepositFee extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                
                text: "Id",
                className: "id",
                align: "left",
                sortable: true,
                cell:(row, index)=>index+1
            },
            {
                key: "name",
                text: "Coin Name",
                className: "name",
                align: "left",
                sortable: true,
                cell: record=>{
                    return (<>
                    <span>{record.name}</span>
                    </>
                    )
                   
                }
               },
            {
                key: "commission",
                text: "Deposit Fees (%)",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "min_deposit",
                text: "Min Limit",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "max_deposit",
                text: "Max Limit",
                className: "name",
                align: "left",
                sortable: true,
            },
           
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-user-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{marginRight: '5px'}}>
                                <i className="fa fa-edit"></i>
                            </button>
                            {/* <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecordMgs(record)}>
                                <i className="fa fa-trash"></i>
                            </button> */}
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "country",
            no_data_text: 'No user found!',
            button: {
                excel: true,
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            cryptoList:[]
        };

        this.state = {
            currentRecord: {
                id: '',
                name: '',
               
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.comdata !== undefined
            && nextProps.comdata.commission !== undefined
            && nextProps.comdata.commission.data !== undefined
            && nextProps.comdata.commission.data.message !== undefined
            && nextProps.comdata.commission.data.success) {
            $('#add-user-modal').modal('hide');
            $('#update-user-modal').modal('hide');
            
            toast(nextProps.comdata.commission.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
        this.getData()
    }

    getData() {
        axios
            .get(baseUrl+"/api/getDepositFee")
            .then(res => {
                console.log("res.data",res.data)
                this.setState({ records: res.data})
            })
            .catch()
            axios
            .get(baseUrl+"/api/crypto-data")
            .then(res => {
               this.setState({cryptoList: res.data})
            })
            .catch()
   
    }

    editRecord(record) {
        this.setState({ currentRecord: record});
    }
    deleteRecordMgs(record) {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Really want to delete this commission?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => this.deleteRecord(record)
              },
              {
                label: 'No'
              
              }
            ]
          });
 

    }
    deleteRecord(record) {
        axios
            .post(baseUrl+"api/withdrawal_commission-delete", {_id: record.id})
            .then(res => {
                if (res.status === 200) {
                   toast(res.data.message, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                  
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <DepositFeeAddModal cryptoList={this.state.cryptoList}/>
                    <DepositFeeUpdateModal record={this.state.currentRecord} cryptoList={this.state.cryptoList}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                            <button className="btn btn-outline-primary float-right mb-3 mr-2" data-toggle="modal" data-target="#add-user-modal"><FontAwesomeIcon icon={faPlus}/> Add </button>
                             <h4 className="mt-2 man_hadding mb-3">Deposit Fee Manager</h4>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

DepositFee.propTypes = {
    auth: PropTypes.object.isRequired,
    comdata: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
    auth: state.auth,
    comdata: state.comdata,
    records: state.records
});

export default connect(
    mapStateToProps
)(DepositFee);
